<template>
  <v-card outlined class="freight-container" :class="pickupDeliveryStyle" :style="style">
    <div
      v-if="showPickupDeliveryHeader"
      class="white--text text-center text-body-1 rounded-sm"
      :class="headerColor"
    >
      {{ headerText }}
    </div>
    <div class="d-flex justify-space-between">
      <v-card-text class="flex-grow-1 py-1 pl-2 pr-0 overflow-hidden">
        <div>
          <span :class="`pickup-delivery-label ${pickupDeliveryStyle} text-no-wrap`">
            {{ pickupDeliveryLabel }}
          </span>
          <span
            v-if="this.freight.scheduleFromDate || this.freight.scheduleToDate"
            v-show="scheduleDateMMDD"
            class="scheduleDateWithTime"
          >
            {{ scheduleDateMMDD }}</span
          >
          <span v-else v-show="scheduleDateMMDD" class="item-value"> {{ scheduleDateMMDD }}</span>
          <span class="item-value">
            {{ freight.pickupDeliveryPoint }}
          </span>
        </div>
        <v-sheet width="100%" color="transparent" v-if="freight.address">
          <PlaceMapDialog :dialogTitle="freight.pickupDeliveryPoint" :address="freight.address">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex justify-start align-center grey--text text--darken-1 text-caption place-text"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small>mdi-map-marker</v-icon>
                <span class="address" style="word-break: break-word">{{ freight.address }}</span>
              </div>
            </template>
          </PlaceMapDialog>
        </v-sheet>
        <div>
          <span class="item-value"> {{ freight.itemName }} </span>
        </div>
      </v-card-text>
      <div class="flex-shrink-0 d-flex align-center">
        <!-- 貨物の詳細を表示するためにアコーディオンメニュー開閉ボタンを押したときにマルチドラッグの選択状態にならないようにするため、mousedownとpointerdownのイベントが伝搬しないようにする -->
        <v-btn icon @click="show = !show" @mousedown.stop @pointerdown.stop>
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="show">
        <v-divider class="mx-1"></v-divider>
        <v-card-text class="px-2 py-0">
          <!-- 荷姿名 -->
          <div v-show="freight.packingNacName">
            <span class="item-name">荷姿：</span>
            <span class="item-value">{{ freight.packingNacName }}</span>
          </div>
          <!-- 送り状番号 -->
          <div v-show="freight.invNumSid">
            <span class="item-name">送り状番号：</span
            ><span class="item-value">{{ freight.invNumSid }}</span>
          </div>
          <!-- 問合せ番号 -->
          <div v-show="freight.inquiryNumber">
            <span class="item-name">お客様問合せ番号：</span
            ><span class="item-value">{{ freight.inquiryNumber }}</span>
          </div>
          <!-- 予定日時 -->
          <div v-show="scheduleDate">
            <span class="item-value">{{ scheduleDate }}</span>
          </div>
          <div v-show="freight.totalWeight">
            <!-- 重量 -->
            <span class="item-value"
              >{{ commonFunction.getDelimiterFew(freight.totalWeight)
              }}<span class="font-italic">kg</span></span
            >

            <span v-show="freight.totalVolume"
              ><span class="item-value">
                <!-- 体積 -->
                <span v-show="freight.totalWeight"> / </span
                >{{ commonFunction.getDelimiterFew(freight.totalVolume)
                }}<span class="font-italic">m<sup>3</sup></span>
              </span>
              <span
                v-show="freight.freightSizeL && freight.freightSizeW && freight.freightSizeH"
                class="text-caption"
              >
                ({{ commonFunction.getDelimiterFew(freight.freightSizeL)
                }}<span class="font-italic pr-1">cm</span> <span class="size-margin">×</span>
                {{ commonFunction.getDelimiterFew(freight.freightSizeW)
                }}<span class="font-italic pr-1">cm</span><span class="size-margin">×</span>
                {{ commonFunction.getDelimiterFew(freight.freightSizeH)
                }}<span class="font-italic">cm</span>)
              </span>
            </span>
          </div>
          <!-- 輸送要件 -->
          <div style="display: flex; flex-wrap: wrap">
            <template v-for="(transportReqItem, index) in freight.transportRequirementList">
              <!-- 選択した輸送要件を表示 -->
              <div
                class="flex-item"
                :key="index"
                style="
                  white-space: nowrap;
                  font-weight: bold;
                  color: orange;
                  overflow-wrap: anywhere;
                "
              >
                {{ transportReqItem }}
                <span v-show="freight.transportRequirementList.length > index + 1">/</span>&nbsp;
              </div>
            </template>
          </div>
          <div v-show="freight.packingRemarks">
            <span class="item-name">備考：</span
            ><span class="item-value">{{ freight.packingRemarks }}</span>
          </div>
          <div v-if="inputDisableFlg">
            <span class="font-weight-bold">納品日時: </span>
            <span class="overflow-word-wrap">{{ deliveryDateTime }}</span>
          </div>
          <!-- 納品日時入力時にマルチドラッグの選択状態にならないようにするため、mousedownとpointerdownのイベントが伝搬しないようにする -->
          <div class="freightDateTimePicker mt-2" @mousedown.stop @pointerdown.stop v-else>
            <DateTimePicker :label="'納品日時'" :required="false" v-model="deliveryDateTime" />
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import PlaceMapDialog from "./PlaceMapDialog.vue";
import DateTimePicker from "../../components/DateTimePicker.vue";
import { commonFunction } from "../../assets/scripts/js/CommonFunction.js";

export default {
  name: "FreightItems",
  components: {
    PlaceMapDialog,
    DateTimePicker,
  },
  props: {
    /** 貨物情報 */
    freight: { type: Object, required: true },
    undispatchFreightFlg: { type: Boolean, required: false, default: false },
    inputDisableFlg: { type: Boolean, required: false, default: false },
    showPickupDeliveryHeader: { type: Boolean, required: false, default: false },
    showDetail: { type: Boolean, required: false, default: false },
    width: { required: false, default: "auto" },
    height: { required: false, defualt: "auto" },
  },
  data() {
    return {
      showDetailTemp: this.$props.showDetail,
      commonFunction: commonFunction,
    };
  },
  mounted() {
    const freightData = this.freight;

    // 未配車貨物エリアのパネル、納品日時に値がなく予定日Toに値があれば予定日Toの値をセットする
    if (
      !(freightData.deliveryDate && freightData.deliveryTime) &&
      freightData.scheduleToDate &&
      this.undispatchFreightFlg
    ) {
      const jstDate = this.freight.scheduleToDate
        ? dateTimeHelper.convertJstDateTime(
            dateTimeHelper.convertUTC2JST(this.freight.scheduleToDate)
          )
        : null;

      this.freight.deliveryDate = `${jstDate.getFullYear()}${(jstDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${jstDate.getDate().toString().padStart(2, "0")}`;
      this.freight.deliveryTime = `${jstDate.getHours().toString().padStart(2, "0")}${jstDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    showDetail: function (newValue) {
      this.showDetailTemp = newValue;
    },
  },
  computed: {
    headerColor() {
      switch (this.freight.freightStatusDiv ?? "0") {
        case "0":
          return "red accent-1";
        case "1":
          return "blue accent-2";
        case "2":
          return "grey darken-1";
        case "3":
          return "blue accent-2";
        case "4":
          return "blue accent-2";
        default:
          return "red accent-1";
      }
    },
    headerText() {
      if (this.freight.pickupDeliveryDiv == "01" || this.freight.pickupDeliveryDiv == "02") {
        switch (this.freight.freightStatusDiv ?? "0") {
          case "0":
            return "未" + this.pickupDeliveryLabel;
          case "1":
            return this.pickupDeliveryLabel + "中";
          case "2":
            return this.pickupDeliveryLabel + "済み";
          case "3":
            return i18n.tc("label.lbl_absence");
          case "4":
            return i18n.tc("label.lbl_return");
          default:
            return "未" + this.pickupDeliveryLabel;
        }
      } else {
        return this.pickupDeliveryLabel;
      }
    },
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    show: {
      get() {
        return this.showDetail || this.showDetailTemp;
      },
      set(newValue) {
        this.showDetailTemp = newValue;
        this.$nextTick(() => this.$emit("change:showDetail", newValue));
      },
    },
    pickupDeliveryLabel() {
      switch (this.freight.pickupDeliveryDiv) {
        case "01":
          return "集荷";
        case "02":
          return "配達";
        case "03":
          return "幹線";
        default:
          return "不明";
      }
    },
    pickupDeliveryStyle() {
      switch (this.freight.pickupDeliveryDiv) {
        case "01":
          return "pickup";
        case "02":
          return "delivery";
        default:
          return "delivery";
      }
    },
    scheduleDateMMDD() {
      // 貨物パネル上部に表示される予定日を取得
      if (!this.freight.scheduleDate) {
        return "";
      }
      const date = dateTimeHelper.convertJstDateTime(
        dateTimeHelper.convertUTC2JST(this.freight.scheduleDate)
      );
      return this.toScheduleDateStr(date).split(" ")[0].slice(5);
    },
    scheduleDate() {
      // FromとToのどちらもなければ scheduleDate の日付を表示する
      if (!this.freight.scheduleFromDate && !this.freight.scheduleToDate) {
        if (!this.freight.scheduleDate) {
          return "";
        }
        const date = dateTimeHelper.convertJstDateTime(
          dateTimeHelper.convertUTC2JST(this.freight.scheduleDate)
        );
        return this.toScheduleDateStr(date).split(" ")[0];
      }

      const jstDateFrom = this.freight.scheduleFromDate
        ? dateTimeHelper.convertJstDateTime(
            dateTimeHelper.convertUTC2JST(this.freight.scheduleFromDate)
          )
        : null;

      const jstDateTo = this.freight.scheduleToDate
        ? dateTimeHelper.convertJstDateTime(
            dateTimeHelper.convertUTC2JST(this.freight.scheduleToDate)
          )
        : null;

      // From がない場合、Toの日時のみ
      if (!jstDateFrom) {
        const toDateAndTime = this.toScheduleDateStr(jstDateTo).split(" ");
        return toDateAndTime[0] + "  ~" + toDateAndTime[2];
      }

      // To がない場合は、From の日時のみ
      if (!jstDateTo) {
        return this.toScheduleDateStr(jstDateFrom) + "~";
      }

      const sameDate =
        jstDateFrom.getFullYear() === jstDateTo.getFullYear() &&
        jstDateFrom.getMonth() === jstDateTo.getMonth() &&
        jstDateFrom.getDate() === jstDateTo.getDate();

      const from = this.toScheduleDateStr(jstDateFrom);
      const to = this.toScheduleDateStr(jstDateTo, sameDate);
      return `${from}~${to}`;
    },
    deliveryDateTime: {
      get() {
        const deliveryDate = this.freight.deliveryDate;
        const deliveryTime = this.freight.deliveryTime;
        if (deliveryDate && deliveryTime) {
          const deliveryDtm =
            deliveryDate.substr(0, 4) +
            "/" +
            deliveryDate.substr(4, 2) +
            "/" +
            deliveryDate.substr(6, 2) +
            " " +
            deliveryTime.substr(0, 2) +
            ":" +
            deliveryTime.substr(2, 2);
          return `${deliveryDtm}`;
        }
        return ``;
      },
      set(newDate) {
        let returnDate = newDate;
        if (newDate) {
          // 入力時は文字列、ピッカーからは日付型で値が来る
          if (toString.call(newDate) === "[object Date]") {
            this.freight.deliveryDate =
              newDate.getFullYear().toString() +
              ("0" + (newDate.getMonth() + 1).toString()).slice(-2) +
              ("0" + newDate.getDate().toString()).slice(-2);
            this.freight.deliveryTime =
              ("0" + newDate.getHours().toString()).slice(-2) +
              ("0" + newDate.getMinutes().toString()).slice(-2);
            returnDate = `${newDate.getFullYear()}/${("0" + (newDate.getMonth() + 1)).slice(-2)}/${(
              "0" + newDate.getDate()
            ).slice(-2)} ${("0" + newDate.getHours()).slice(-2)}:${(
              "0" + newDate.getMinutes()
            ).slice(-2)}`;
          } else if (typeof newDate === "string" && newDate.length == 16) {
            this.freight.deliveryDate =
              newDate.substr(0, 4) + newDate.substr(5, 2) + newDate.substr(8, 2);
            this.freight.deliveryTime = newDate.substr(11, 2) + newDate.substr(14, 2);
          }
        } else {
          this.freight.deliveryDate = null;
          this.freight.deliveryTime = null;
        }
        this.$emit("input", returnDate);
      },
    },
  },
  methods: {
    toScheduleDateStr: function (date, timeonly = false) {
      const dateStr = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      const timeStr = `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
      return timeonly ? timeStr : `${dateStr}  ${timeStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.freight-container {
  --pickup-color: 158 132 0;
  --delivery-color: 0 0 139;
  &.pickup {
    border: 2px solid rgb(var(--pickup-color) / 70%) !important;
  }

  &.delivery {
    border: 2px solid rgb(var(--delivery-color) / 70%) !important;
  }

  .pickup-delivery-label {
    display: inline-block;
    font-weight: 600;
    font-size: 0.9rem;
    width: min-content;
    line-height: 1rem;
    height: min-content;

    &.pickup {
      background: linear-gradient(transparent 80%, rgb(var(--pickup-color) / 40%) 80%);
    }

    &.delivery {
      background: linear-gradient(transparent 80%, rgb(var(--delivery-color) / 40%) 80%);
    }
  }

  .overflow-word-wrap {
    overflow-wrap: break-word;
  }
}

.place-text:hover {
  cursor: pointer;
  & > span.address {
    text-decoration: underline;
  }
}

.item-name {
  font-weight: bold;
}

.item-value {
  font-size: 12px;
  white-space: pre-wrap;
}

.scheduleDateWithTime {
  font-size: 12px;
  white-space: pre-wrap;
  color: #039be5;
  font-weight: bold;
}

.freightDateTimePicker ::v-deep {
  .date-time-picker input {
    font-size: 13px;
    padding-top: 0;
  }
  .v-label {
    font-size: 13px;
  }
}
</style>
