<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_dispatchList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- カレンダー -->
            <!-- 運行日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="operationDate"
                :label="$t('label.lbl_operationDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCalender"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="operationDateCal"
                  @input="dateCalender = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->

            <!-- 積載量下限値 -->
            <div style="width: 135px">
              <v-text-field
                outlined
                dense
                v-model="payloadMin"
                @change="changePayloadMin(payloadMin)"
                :label="$t('label.lbl_payloadMin')"
                :rules="[
                  rules.isNumber,
                  rules.isRangeCheck,
                  rules.useComma,
                  rules.payload(payloadMin, payloadMax),
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span style="margin-top: 7px">&nbsp;kg</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 積載量上限値 -->
            <div style="width: 135px">
              <v-text-field
                outlined
                dense
                v-model="payloadMax"
                @change="changePayloadMax(payloadMax)"
                :label="$t('label.lbl_payloadMax')"
                :rules="[rules.isNumber, rules.isRangeCheck, rules.useComma]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span style="margin-top: 7px">&nbsp;kg</span>

            <!-- 計画区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="planOnFlgSelected"
                :items="planOnFlgList"
                :label="$t('label.lbl_planOnFlg')"
                :hint="setPlanOnFlg()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSidSelected"
                :items="officeList"
                :label="$t('label.lbl_salesOffice')"
                :hint="setOffice()"
                :rules="[rules.inputRequired]"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>

            <span class="require" style="float: left">*</span>
            <!-- 集配幹線区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupDeliveryTrunkFlgSelected"
                :items="pickupDeliveryTrunkFlgList"
                :label="$t('label.lbl_pickupDeliveryTrunkFlg')"
                :hint="setPickupDeliveryTrunkFlg()"
                :rules="[rules.inputRequired]"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>

            <span class="require" style="float: left">*</span>
          </v-row>
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="customerSelected"
                :items="customerList"
                :label="$t('label.lbl_supplier')"
                :hint="setCustomer()"
                :search-input.sync="customerInput"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 車両 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="vehicleMajorSelected"
                :items="vehicleMajorList"
                :label="$t('label.lbl_vehicle')"
                :hint="setVehicleMajor()"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="vehicleMinorSelected"
                :items="vehicleMinorList"
                :hint="setVehicleMinor()"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 乗務員 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="driverSelected"
                :items="driverList"
                :label="$t('label.lbl_driver')"
                :hint="setDriver()"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left; width: 60%">
                <v-row>
                  <v-col style="max-width: 280px">
                    <!-- 検索ボタン -->
                    <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                      $t("btn.btn_search")
                    }}</v-btn>
                    <!--画面クリアボタン-->
                    <v-btn class="other-btn" @click="clearScreen()">{{
                      $t("btn.btn_clear")
                    }}</v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      :value="vehicleSort.vehicleSort1"
                      @change="(value) => (vehicleSort = { ...vehicleSort, vehicleSort1: value })"
                      :items="vehicleSortItems"
                      :label="'第1ソート'"
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      :value="vehicleSort.vehicleSort2"
                      @change="(value) => (vehicleSort = { ...vehicleSort, vehicleSort2: value })"
                      :items="vehicleSortItems"
                      :label="'第2ソート'"
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <!-- 配車編集・データ更新・連携領域 -->
              <div class="btn-update-area" style="float: right">
                <!-- 配車表出力ボタン -->
                <v-btn
                  v-bind:disabled="isPush"
                  color="primary"
                  class="api-btn"
                  @click="outputVehicleTable()"
                  >{{ $t("btn.btn_vehicleTable") }}
                </v-btn>
                <!-- 配車編集ボタン -->
                <v-btn
                  v-bind:disabled="isPush"
                  color="primary"
                  class="api-btn"
                  @click="openRegisterDispatch()"
                  >{{ $t("btn.btn_edit_dispatch") }}
                </v-btn>
                <!-- 確定ボタン -->
                <v-btn
                  v-bind:disabled="isPush"
                  color="primary"
                  class="api-btn"
                  @click="dataLink(2)"
                  >{{ $t("btn.btn_confirm") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid class="main-area">
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="600px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }" class="product-fixed1">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>

          <!-- 車両 -->
          <template v-slot:[`item.carSid`]="{ item }">
            <div>
              {{ item.carShape + "\n" + "(" + item.carShago + ")" }}
            </div>
            <div>{{ item.carPayload }}kg</div>
          </template>

          <!-- 乗務員 -->
          <template v-slot:[`item.driverUserList`]="{ item }">
            <div v-if="item.driverUserList.length == 0">{{ $t("label.lbl_noDriver") }}</div>
            <div v-else-if="item.driverUserList.length == 1">{{ item.driverUserList[0] }}</div>
            <div v-else>{{ item.driverUserList[0] }} ほか</div>
          </template>

          <!-- 計画区分 -->
          <template v-slot:[`item.planOnFlg`]="{ item }">
            <div v-if="item.planOnFlg == 0">仮組</div>
            <div v-if="item.planOnFlg == 1">確定</div>
            <div v-if="item.planOnFlg == 2">未配車</div>
            <div v-if="item.planOnFlg == 3">仮組/確定</div>
          </template>

          <!-- 便情報一覧 -->
          <template v-slot:[`item.dispatchBinList`]="{ item }">
            <div class="transport-info-area" v-if="item.dispatchBinList != null">
              <dl
                v-for="(dispatchBin, binIndex) in item.dispatchBinList"
                :key="dispatchBin.transportSid"
              >
                <v-card
                  class="transport-info-box"
                  :style="getBinTabWidth(dispatchBin.towingDispatchBin, '0')"
                  :class="getBackgroundColor(dispatchBin.binStatusDiv)"
                >
                  <!-- <div style="font-weight: bold">
                    総走行距離：
                    <span v-if="dispatchBin.transportDistance != null">
                      {{ dispatchBin.transportDistance }}
                    </span>
                    <span v-else>0</span>
                  </div> -->
                  <v-btn class="map-icon" icon>
                    <PickupDeliveryMapDialog
                      :openLabel="''"
                      :dialogTitle="`${item.carShape} ${item.carShago} 第${binIndex + 1}便`"
                      :origin="officeAddressMap[dispatchBin.transportSid]"
                      :destination="officeAddressMap[dispatchBin.transportSid]"
                      :waypoints="waypoints(dispatchBin.dispatchedFreightDtl)"
                    >
                      <FreightItem
                        v-for="(freight, freightIndex) in dispatchBin.dispatchedFreightDtl"
                        :key="freightIndex"
                        :freight="freight"
                        :inputDisableFlg="true"
                        :showPickupDeliveryHeader="true"
                        class="mb-1"
                      ></FreightItem>
                    </PickupDeliveryMapDialog>
                  </v-btn>
                  <div
                    style="
                      font-size: 14px;
                      color: white;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p style="margin-bottom: 0px">
                      <span style="font-weight: bold">
                        {{ binIndex + 1 }}便&ensp;
                        <span>{{ binStatusText(dispatchBin.binStatusDiv) }}</span>
                      </span>
                      <br />
                      <span v-if="dispatchBin.fromDate == null && dispatchBin.toDate == null">
                        &nbsp;
                      </span>
                      <span v-if="dispatchBin.fromDate == null && dispatchBin.toDate != null">
                        ~
                        {{ dateTimeHelper.convertUTC2JST(dispatchBin.toDate).substring(11, 16) }}
                      </span>
                      <span v-if="dispatchBin.fromDate != null && dispatchBin.toDate == null">
                        {{ dateTimeHelper.convertUTC2JST(dispatchBin.fromDate).substring(11, 16) }}
                        ~
                      </span>
                      <span v-if="dispatchBin.fromDate != null && dispatchBin.toDate != null">
                        {{ dateTimeHelper.convertUTC2JST(dispatchBin.fromDate).substring(11, 16) }}
                        ~
                        {{ dateTimeHelper.convertUTC2JST(dispatchBin.toDate).substring(11, 16) }}
                      </span>
                      <br />
                      <span>マウスオーバーで詳細表示</span>
                    </p>
                  </div>
                  <div
                    class="tooltip-content"
                    :style="getBinTabWidth(dispatchBin.towingDispatchBin, '1')"
                  >
                    <div :style="getBinTabWidth(dispatchBin.towingDispatchBin, '2')">
                      <div style="text-align: center; font-size: 16px">
                        <span v-if="dispatchBin.towingDispatchBin != null">牽引</span>便情報
                      </div>
                      <div>乗務員：{{ dispatchBin.driverUserName }}</div>
                      <div v-if="pickupDeliveryTrunkFlg == '1'">
                        目的拠点：{{ dispatchBin.endBase }}
                      </div>
                      <div v-if="pickupDeliveryTrunkFlg == '1'">
                        立寄拠点：{{ dispatchBin.toPoint }}
                      </div>
                      <div>便備考：</div>
                      <div style="margin-left: 10px">
                        {{ dispatchBin.remarks != "" ? dispatchBin.remarks : "なし" }}
                      </div>
                      <div>貨物情報リスト：</div>
                      <dl
                        v-for="(moduleInfo, index) in dispatchBin.dispatchedModuleDtl"
                        :key="index"
                        style="margin-left: 10px"
                      >
                        <dt>モジュール{{ index + 1 }}</dt>
                        <dd>治具：{{ moduleInfo.jigName }}</dd>
                        <dd>貨物数：{{ moduleInfo.freightQuantity }}</dd>
                        <dd>荷姿：{{ moduleInfo.packingNacName }}</dd>
                        <dd>
                          サイズ（L*W*H）：{{
                            moduleInfo.freightSizeL +
                            "*" +
                            moduleInfo.freightSizeW +
                            "*" +
                            moduleInfo.freightSizeH
                          }}
                        </dd>
                        <dd>総重量kg：{{ moduleInfo.totalWeight }}kg</dd>
                        <dd>総体積m3： {{ moduleInfo.totalVolume }}㎥</dd>
                        <dd>モジュール備考： {{ moduleInfo.remarks }}</dd>
                        <dd><br /></dd>
                      </dl>
                      <dl
                        v-for="(freightInfo, index) in dispatchBin.dispatchedFreightDtl"
                        :key="index"
                        style="margin-left: 10px"
                      >
                        <dt>貨物{{ index + 1 }}</dt>
                        <dd>代表品名：{{ freightInfo.itemName }}</dd>
                        <dd>
                          サイズ（L*W*H）：{{
                            freightInfo.freightSizeL +
                            "*" +
                            freightInfo.freightSizeW +
                            "*" +
                            freightInfo.freightSizeH
                          }}
                        </dd>
                        <dd>重量：{{ freightInfo.totalWeight }}kg</dd>
                        <dd>体積：{{ freightInfo.totalVolume }}㎥</dd>
                        <dd>
                          集配区分：
                          <span v-if="freightInfo.pickupDeliveryDiv == '01'">集荷</span>
                          <span v-else>配達</span>
                        </dd>
                        <dd>
                          予定日時：
                          <span
                            v-if="
                              freightInfo.scheduleFromDate == null &&
                              freightInfo.scheduleToDate == null
                            "
                            >指定なし</span
                          >
                          <span v-else>
                            <span v-if="freightInfo.scheduleFromDate != null">
                              {{
                                dateTimeHelper
                                  .convertUTC2JST(freightInfo.scheduleFromDate)
                                  .substring(0, 16)
                              }}
                            </span>
                            <span>~</span>
                            <span v-if="freightInfo.scheduleToDate != null">
                              {{
                                dateTimeHelper
                                  .convertUTC2JST(freightInfo.scheduleToDate)
                                  .substring(0, 16)
                              }}
                            </span>
                          </span>
                        </dd>
                        <dd>目的地名：{{ freightInfo.pickupDeliveryPoint }}</dd>
                        <dd>目的地郵便番号：{{ freightInfo.postcode }}</dd>
                        <dd>目的地住所：{{ freightInfo.address }}</dd>
                        <dd>
                          納品日時：
                          <span
                            v-if="
                              freightInfo.deliveryDate != '' && freightInfo.deliveryDate != null
                            "
                          >
                            {{
                              freightInfo.deliveryDate.slice(0, 4) +
                              "/" +
                              freightInfo.deliveryDate.slice(4, 6) +
                              "/" +
                              freightInfo.deliveryDate.slice(6)
                            }}
                          </span>
                          &nbsp;
                          <span
                            v-if="
                              freightInfo.deliveryTime != '' && freightInfo.deliveryTime != null
                            "
                          >
                            {{
                              freightInfo.deliveryTime.slice(0, 2) +
                              ":" +
                              freightInfo.deliveryTime.slice(2)
                            }}
                          </span>
                        </dd>
                        <dd>荷姿備考：{{ freightInfo.packingRemarks }}</dd>
                        <dd><br /></dd>
                      </dl>
                    </div>
                    <div
                      v-if="dispatchBin.towingDispatchBin != null"
                      :style="getBinTabWidth(dispatchBin.towingDispatchBin, '3')"
                    >
                      <div style="text-align: center; font-size: 16px">被牽引便情報</div>
                      <div>乗務員：{{ dispatchBin.towingDispatchBin.driverUserName }}</div>
                      <div>目的拠点：{{ dispatchBin.towingDispatchBin.endBase }}</div>
                      <div>立寄拠点：{{ dispatchBin.towingDispatchBin.toPoint }}</div>
                      <div>便備考：</div>
                      <div style="margin-left: 10px">
                        {{
                          dispatchBin.towingDispatchBin.remarks != ""
                            ? dispatchBin.towingDispatchBin.remarks
                            : "なし"
                        }}
                      </div>
                      <div>貨物情報リスト：</div>
                      <dl
                        v-for="(moduleInfo, index) in dispatchBin.towingDispatchBin
                          .dispatchedModuleDtl"
                        :key="index"
                        style="margin-left: 10px"
                      >
                        <dt>モジュール{{ index + 1 }}</dt>
                        <dd>治具：{{ moduleInfo.jigName }}</dd>
                        <dd>貨物数：{{ moduleInfo.freightQuantity }}</dd>
                        <dd>荷姿：{{ moduleInfo.packingNacName }}</dd>
                        <dd>
                          サイズ（L*W*H）：{{
                            moduleInfo.freightSizeL +
                            "*" +
                            moduleInfo.freightSizeW +
                            "*" +
                            moduleInfo.freightSizeH
                          }}
                        </dd>
                        <dd>総重量kg：{{ moduleInfo.totalWeight }}kg</dd>
                        <dd>総体積m3： {{ moduleInfo.totalVolume }}㎥</dd>
                        <dd>モジュール備考： {{ moduleInfo.remarks }}</dd>
                        <dd><br /></dd>
                      </dl>
                      <dl
                        v-for="(freightInfo, index) in dispatchBin.towingDispatchBin
                          .dispatchedFreightDtl"
                        :key="index"
                        style="margin-left: 10px"
                      >
                        <dt>貨物{{ index + 1 }}</dt>
                        <dd>代表品名：{{ freightInfo.itemName }}</dd>
                        <dd>
                          サイズ（L*W*H）：{{
                            freightInfo.freightSizeL +
                            "*" +
                            freightInfo.freightSizeW +
                            "*" +
                            freightInfo.freightSizeH
                          }}
                        </dd>
                        <dd>重量：{{ freightInfo.totalWeight }}kg</dd>
                        <dd>体積：{{ freightInfo.totalVolume }}㎥</dd>
                        <dd>
                          集配区分：
                          <span v-if="freightInfo.pickupDeliveryDiv == '01'">集荷</span>
                          <span v-else>配達</span>
                        </dd>
                        <dd>
                          予定日時：
                          <span
                            v-if="
                              freightInfo.scheduleFromDate == null &&
                              freightInfo.scheduleToDate == null
                            "
                            >指定なし</span
                          >
                          <span v-else>
                            <span v-if="freightInfo.scheduleFromDate != null">
                              {{
                                dateTimeHelper
                                  .convertUTC2JST(freightInfo.scheduleFromDate)
                                  .substring(0, 16)
                              }}
                            </span>
                            <span>~</span>
                            <span v-if="freightInfo.scheduleToDate != null">
                              {{
                                dateTimeHelper
                                  .convertUTC2JST(freightInfo.scheduleToDate)
                                  .substring(0, 16)
                              }}
                            </span>
                          </span>
                        </dd>
                        <dd>目的地名：{{ freightInfo.pickupDeliveryPoint }}</dd>
                        <dd>目的地郵便番号：{{ freightInfo.postcode }}</dd>
                        <dd>目的地住所：{{ freightInfo.address }}</dd>
                        <dd>
                          納品日時：
                          <span
                            v-if="
                              freightInfo.deliveryDate != '' && freightInfo.deliveryDate != null
                            "
                          >
                            {{
                              freightInfo.deliveryDate.slice(0, 4) +
                              "/" +
                              freightInfo.deliveryDate.slice(4, 6) +
                              "/" +
                              freightInfo.deliveryDate.slice(6)
                            }}
                          </span>
                          &nbsp;
                          <span
                            v-if="
                              freightInfo.deliveryTime != '' && freightInfo.deliveryTime != null
                            "
                          >
                            {{
                              freightInfo.deliveryTime.slice(0, 2) +
                              ":" +
                              freightInfo.deliveryTime.slice(2)
                            }}
                          </span>
                        </dd>
                        <dd>荷姿備考：{{ freightInfo.packingRemarks }}</dd>
                        <dd><br /></dd>
                      </dl>
                    </div>
                  </div>
                </v-card>
              </dl>
            </div>
            <div v-else>空車です。配車登録してください。</div>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :title="confirmDialog.title"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
        :cancelBtnFlg="confirmDialog.cancelBtnFlg"
        :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
      />

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import commonRules from "@/mixins/CommonRules";
import PickupDeliveryMapDialog from "./PickupDeliveryMapDialog.vue";
import FreightItem from "./FreightItem.vue";

export default {
  name: appConfig.SCREEN_ID.P_DVP_001,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    PickupDeliveryMapDialog,
    FreightItem,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_100: false,
    involce: "",
    dateTimeHelper: dateTimeHelper,
    // メニュー
    openMenu: null,
    // ボタン活性
    isPush: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 編集用チェックボックス
    changeListCheckbox: [],
    listCheckboxBtn: [],
    // 運行日
    operationDate: "",
    operationDateCal: "",
    // 運行日カレンダー
    dateCalender: false,
    // 積載量下限・上限
    payloadMin: "",
    payloadMax: "",
    // 計画区分
    planOnFlgSelected: "",
    // 計画区分プルダウン
    planOnFlgList: [],
    // 営業所プルダウン
    officeSidSelected: "",
    // 取引先プルダウン
    customerSelected: "",
    // 乗務員プルダウン
    driverSelected: "",
    // 車両リストプルダウン
    vehicleMajorSelected: "",
    vehicleMinorSelected: "",
    // 集配幹線プルダウン
    pickupDeliveryTrunkFlgSelected: "",
    pickupDeliveryTrunkFlg: "0",
    // 営業所プルダウンデータ
    officeList: [],
    // 取引先プルダウンデータ
    customerList: [],
    // 乗務員プルダウンデータ
    driverList: [],
    // 車両プルダウンデータ
    vehicleMajorList: [],
    vehicleMinorList: [],
    // 集配幹線プルダウンデータ
    pickupDeliveryTrunkFlgList: [],
    // 計画区分
    updStatusDiv: 0,
    // 計画区分更新対象リスト
    updateBinList: [],
    // 配車編集対象リスト
    visibleBinList: [],
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 初期データ
    defaultData: [],
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 住所マップ
    officeAddressMap: {},
    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 車両
      {
        text: i18n.tc("label.lbl_vehicle"),
        value: "carSid",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 乗務員
      {
        text: i18n.tc("label.lbl_driver"),
        value: "driverUserList",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 計画区分
      {
        text: i18n.tc("label.lbl_planOnFlg"),
        value: "planOnFlg",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 便一覧
      {
        text: i18n.tc("label.lbl_transportList"),
        value: "dispatchBinList",
        width: "auto",
        align: "center",
        sortable: false,
      },
    ],
    // 入力チェック
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      useComma: (value) =>
        value == null || value == "" || !value.includes(",") || i18n.tc("check.chk_inputNumber"),
      payload: (value1, value2) => {
        if (value2 != null && value2 != undefined && value2 != "") {
          if (!(value1 == null || value1 == "") && parseFloat(value1) > parseFloat(value2)) {
            return i18n.tc("check.chk_correlation_payload");
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
    // 検索パラメータ
    searchParam: {
      searchOperationDate: "",
      searchOperationDateCal: "",
      searchPayloadMin: "",
      searchPayloadMax: "",
      searchPlanOnFlgSelected: "",
      searchOfficeSelected: "",
      searchCustomerSelected: "",
      searchDriverSelected: "",
      searchVehicleMajorSelected: "",
      searchVehicleMinorSelected: "",
      searchPickupDeliveryTrunkFlgSelected: "",
    },
    customerInput: "",
    vehicleSort: {
      vehicleSort1: null,
      vehicleSortOrder1: "ASC",
      vehicleSort2: null,
      vehicleSortOrder2: "ASC",
    },

    vehicleSortItems: [
      { text: "形状", value: "carShape" },
      { text: "車号", value: "carShago" },
      { text: "積載量", value: "carPayload" },
    ],
  }),

  created() {
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
    }
    this.operationDate = this.$route.params.searchOperationDate;
    this.operationDateCal = this.$route.params.searchOperationDateCal;
    this.payloadMin = this.searchParam.searchPayloadMin;
    this.payloadMax = this.searchParam.searchPayloadMax;
    this.planOnFlgSelected = this.searchParam.searchPlanOnFlgSelected;
    this.officeSidSelected = this.searchParam.searchOfficeSelected;
    // 取引先
    this.customerSelected = this.searchParam.searchCustomerSelected;
    // 乗務員
    this.driverSelected = this.searchParam.searchDriverSelected;
    // 車両
    this.vehicleMajorSelected = this.searchParam.searchVehicleMajorSelected;
    this.vehicleMinorSelected = this.searchParam.searchVehicleMinorSelected;
    // 集配幹線区分
    this.pickupDeliveryTrunkFlgSelected = this.searchParam.searchPickupDeliveryTrunkFlgSelected;
  },

  methods: {
    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.getMstCode();
      this.getOfficeInfo();
      // 乗務員情報を取得
      this.getDriverInfo();
      if (this.operationDate) {
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.inputList = [];
        this.operationDate = dateTimeHelper.convertJST();
        this.operationDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.planOnFlgSelected = "";
        this.officeSidSelected = sessionStorage.getItem("sales_office_sid");
        this.customerSelected = "";
        this.driverSelected = "";
        this.vehicleMajorSelected = "";
        this.vehicleMinorSelected = "";
        this.pickupDeliveryTrunkFlgSelected = "0";
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 車両形状（大項目）
      const vehicleMajorList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.VEHICLE_SHAPE_MAJOR_DIV,
      });
      // 車両形状（小項目）
      const vehicleMinorList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.VEHICLE_SHAPE_MINOR_DIV,
      });
      // 集配幹線区分
      const pickupDeliveryTrunkFlgList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.PICKUP_DELIVERY_TRUNK_DIV,
      });
      // 計画区分
      const planOnFlgList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.PLAN_ON_FLG,
      });

      Promise.all([vehicleMajorList, vehicleMinorList, pickupDeliveryTrunkFlgList, planOnFlgList])
        .then((result) => {
          // リスト情報の設定
          let list = [{ text: "", name: "", value: "" }];
          this.vehicleMajorList = list.concat(result[0]);
          this.vehicleMinorList = list.concat(result[1]);
          this.pickupDeliveryTrunkFlgList = list.concat(result[2]);
          this.planOnFlgList = list.concat(result[3]);
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });

      this.loadingCounter = 0;
    },

    // 営業所プルダウン
    getOfficeInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 拠点管理種別（2=営業所を取得）
      config.params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      config.params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      config.params.selfEstablishedLocationsKbn = "1";
      return new Promise((resolve, reject) => {
        this.$httpClient
          //TODO 拠点マスタ取得のPC側実装後はそちらに合わせる
          .secureGet(appConfig.API_URL.MST_BIZ_BASE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.baseInfoList.forEach((row) => {
                list.push({
                  text: row.baseName ?? "",
                  value: row.baseSid ?? "",
                  name: row.baseName ?? "",
                });
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取引先データ取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};
      // 取引先コード（前方一致）
      params.supplierCode = val;

      const clientList = getParameter.getTmsBizCustomerMst(params);
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.customerList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 乗務員プルダウン
    getDriverInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_DRIVER_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.drivers.forEach((row) => {
                list.push({
                  text: row.driverUserName ?? "",
                  value: row.driverUserSid ?? "",
                  name: row.driverUserName ?? "",
                });
              });
              this.driverList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 積載量下限値を適切な形式に変換
     */
    changePayloadMin(payloadMin) {
      if (
        payloadMin != null &&
        !payloadMin.match(/^-?[0-9]+\.[0-9]+$/) &&
        /[０-９]/.test(payloadMin)
      ) {
        payloadMin = payloadMin.replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
      }
      if (
        payloadMin != null &&
        !payloadMin.match(/^-?[0-9]+\.[0-9]+$/) &&
        /[\D]/.test(payloadMin)
      ) {
        payloadMin = payloadMin.replace(payloadMin, 0);
      }
      this.payloadMin = payloadMin;
    },

    /**
     * 積載量上限値を適切な形式に変換
     */
    changePayloadMax(payloadMax) {
      if (
        payloadMax != null &&
        !payloadMax.match(/^-?[0-9]+\.[0-9]+$/) &&
        /[０-９]/.test(payloadMax)
      ) {
        payloadMax = payloadMax.replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
      }
      if (
        payloadMax != null &&
        !payloadMax.match(/^-?[0-9]+\.[0-9]+$/) &&
        /[\D]/.test(payloadMax)
      ) {
        payloadMax = payloadMax.replace(payloadMax, 0);
      }
      this.payloadMax = payloadMax;
    },

    /**
     * 配車一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckBox = [];
        this.listCheckBox = true;
        // 編集用リストを初期化
        this.changeListCheckbox = [];
        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.changeListCheckbox.push(this.inputList[i]);
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckBox = [];
        this.listCheckBox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 編集用リストを初期化
          this.changeListCheckbox = [];
          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 配車一覧画面：検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // 単項目チェック
      let inputCheckResult = this.$refs.form.validate();
      if (!inputCheckResult) {
        return;
      }

      // 入力チェック結果フラグ
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.createalertMessage = "";
      if (
        // 積載量入力チェック
        parseFloat(this.payloadMin) > parseFloat(this.payloadMax)
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      if (check == 0) {
        // 初期検索かページング検索かを判断
        if (checkBoxFlag) {
          this.isAllSelected = false;
        }

        // 検索条件を保存
        this.searchParam.searchOperationDate = this.operationDate;
        this.searchParam.searchOperationDateCal = this.operationDateCal;
        this.searchParam.searchPayloadMin = this.payloadMin;
        this.searchParam.searchPayloadMax = this.payloadMax;
        this.searchParam.searchPlanOnFlgSelected = this.planOnFlgSelected;
        this.searchParam.searchOfficeSelected = this.officeSidSelected;
        // 取引先
        this.searchParam.searchCustomerSelected = this.customerSelected;
        // 乗務員
        this.searchParam.searchDriverSelected = this.driverSelected;
        // 車両
        this.searchParam.searchVehicleMajorSelected = this.vehicleMajorSelected;
        this.searchParam.searchVehicleMinorSelected = this.vehicleMinorSelected;
        // 集配幹線区分
        this.searchParam.searchPickupDeliveryTrunkFlgSelected = this.pickupDeliveryTrunkFlgSelected;

        // 検索条件の営業所によって操作を制限
        if (
          !this.officeSidSelected ||
          this.officeSidSelected == sessionStorage.getItem("sales_office_sid")
        ) {
          // 入力営業所がログインユーザーの所属営業所と同じ場合、仮組に戻す・確定ボタンを活性化
          this.isPush = false;
        } else {
          // 入力営業所がログインユーザーの所属営業所と異なる場合、仮組に戻す・確定ボタンを非活性化
          this.isPush = true;
        }

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();

        // 運行日
        config.params.operationDate = dateTimeHelper.convertUTC(this.operationDate);
        // 積載量下限値
        if (this.payloadMin) {
          config.params.payloadMin = this.payloadMin;
        }
        // 積載量上限値
        if (this.payloadMax) {
          config.params.payloadMax = this.payloadMax;
        }
        // 計画区分
        if (this.planOnFlgSelected) {
          config.params.planOnFlg = this.planOnFlgSelected;
        }
        // 営業所SID
        config.params.officeSid = this.officeSidSelected;

        // 取引先CD
        if (this.customerSelected) {
          config.params.supplierCd = this.customerSelected;
        }
        // 乗務員
        if (this.driverSelected) {
          config.params.userSid = this.driverSelected;
        }
        // 車両形状（大項目）
        if (this.vehicleMajorSelected) {
          config.params.vehicleShapeMajor = this.vehicleMajorSelected;
        }
        // 車両形状（小項目）
        if (this.vehicleMinorSelected) {
          config.params.vehicleShapeMinor = this.vehicleMinorSelected;
        }
        // 集配幹線フラグ
        config.params.pickupDeliveryTrunkFlg = this.pickupDeliveryTrunkFlgSelected;

        // ページャー処理
        if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;

        if (this.vehicleSort.vehicleSort1) {
          config.params.vehicleSort1 = this.vehicleSort.vehicleSort1;
          config.params.vehicleSortOrder1 = this.vehicleSort.vehicleSortOrder1;
        }
        if (
          this.vehicleSort.vehicleSort2 &&
          this.vehicleSort.vehicleSort2 != this.vehicleSort.vehicleSort1
        ) {
          config.params.vehicleSort2 = this.vehicleSort.vehicleSort2;
          config.params.vehicleSortOrder2 = this.vehicleSort.vehicleSortOrder2;
        }

        this.inputList = [];
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_DISPATCH_INFORMATION_LIST, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);

              // headerItems設定
              this.headerItems.push(
                {
                  text: "選択",
                  value: "check",
                  width: "5%",
                  align: "center",
                  sortable: false,
                },
                // 車両
                {
                  text: i18n.tc("label.lbl_vehicle"),
                  value: "carSid",
                  width: "10%",
                  align: "center",
                  sortable: false,
                },
                // 乗務員
                {
                  text: i18n.tc("label.lbl_driver"),
                  value: "driverUserList",
                  width: "10%",
                  align: "center",
                  sortable: false,
                },
                // 計画区分
                {
                  text: i18n.tc("label.lbl_planOnFlg"),
                  value: "planOnFlg",
                  width: "10%",
                  align: "center",
                  sortable: false,
                },
                // 便一覧
                {
                  text: i18n.tc("label.lbl_transportList"),
                  value: "dispatchBinList",
                  width: "auto",
                  align: "center",
                  sortable: false,
                }
              );

              // 成功
              if (jsonData.resCom.resComCode == "000") {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const dispatchVehicleList = [];
                let planOnFlg = "";
                // 便一覧を取得
                jsonData.resIdv.dispatchVehicle.forEach((vehicle) => {
                  let driverUserNameList = [];
                  if (vehicle.dispatchBinList != null) {
                    // 便が1件以上存在する場合
                    // 仮組便リスト
                    const plan0 = [];
                    // 確定便リスト
                    const plan1 = [];
                    for (let item of vehicle.dispatchBinList) {
                      // 便ごとの乗務員を保存
                      driverUserNameList.push(item.driverUserName);
                      // 便ごとの計画区分を判定
                      if (item.planOnFlg == "0") {
                        plan0.push(item.transportSid);
                      } else {
                        plan1.push(item.transportSid);
                      }
                    }
                    // 1車両に登録された便の計画区分によって表示を出し分ける
                    if (plan0.length == 0) {
                      // 確定の便のみであった場合
                      planOnFlg = "1";
                    } else if (plan1.length == 0) {
                      // 仮組の便のみであった場合
                      planOnFlg = "0";
                    } else {
                      // 仮組と確定が混在していた場合
                      planOnFlg = "3";
                    }
                  } else {
                    // 便が登録されていない場合
                    planOnFlg = "2";
                  }
                  const driverUserList = Array.from(new Set(driverUserNameList));
                  dispatchVehicleList.push({
                    // 車両SID
                    carSid: vehicle.carSid,
                    // 車両
                    carShago: vehicle.carShago,
                    // 車両形状
                    carShape: vehicle.carShape,
                    // 積載量
                    carPayload: vehicle.carPayload,
                    // 乗務員リスト
                    driverUserList: driverUserList,
                    // 計画区分
                    planOnFlg: planOnFlg,
                    // 便情報一覧
                    dispatchBinList: vehicle.dispatchBinList,
                    // チェックボックス
                    check: false,
                  });
                  if (vehicle.dispatchBinList != null) {
                    for (var i = 0; i < vehicle.dispatchBinList.length; i++) {
                      this.getOfficeAddress(
                        vehicle.dispatchBinList[i].transportSid,
                        vehicle.dispatchBinList[i].officeSid
                      );
                    }
                  }
                });
                this.inputList = dispatchVehicleList;
                this.pickupDeliveryTrunkFlg = this.pickupDeliveryTrunkFlgSelected;
                if (!checkBoxFlag) {
                  // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                  for (var k = 0; k < this.inputList.length; k++) {
                    for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                      if (this.inputList[k].carSid == this.listCheckboxBtn[j].carSid) {
                        this.inputList[k].check = true;
                      }
                    }
                  }
                } else {
                  // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                  this.listCheckboxBtn.length = 0;
                }

                // ページング検索の場合の全選択チェックボックス処理
                if (this.isAllSelected) {
                  this.isAllSelected = true;
                  for (var i = 0; i < this.inputList.length; i++) {
                    this.inputList[i].check = true;
                  }
                }
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 便の色切替
     */
    getBackgroundColor(binStatusDiv) {
      switch (binStatusDiv ?? "0") {
        case "0":
          return "red accent-1";
        case "1":
          return "blue accent-2";
        case "2":
          return "grey darken-1";
        default:
          return "red accent-1";
      }
    },
    getBinTabWidth(towingDispatchBin, flg) {
      if (this.pickupDeliveryTrunkFlg == "1" && towingDispatchBin != null) {
        switch (flg) {
          case "0":
            return "width: 505px";
          case "1":
            return "width: 480px";
          case "2":
            return "float: left; width: 49%";
          case "3":
            return "float: right; width: 49%";
          default:
            return "";
        }
      }

      return "";
    },
    /**
     * 便状況の表示テキスト切替
     */
    binStatusText(binStatusDiv) {
      switch (binStatusDiv ?? "0") {
        case "0":
          return this.$t("label.lbl_unPickupUnDelivered");
        case "1":
          return this.$t("label.lbl_duringPickupDelivery");
        case "2":
          return this.$t("label.lbl_pickupDeliveryComplete");
        default:
          return this.$t("label.lbl_unPickupUnDelivered");
      }
    },

    /**
     * 便を担当する営業所の住所を取得
     */
    getOfficeAddress(transportSid, officeSid) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // パラメータ設定
      const config = this.$httpClient.createGetApiRequestConfig();
      // 拠点SID
      config.params.baseSid = officeSid;
      // 拠点管理種別（2=営業所を取得）
      config.params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      config.params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      config.params.selfEstablishedLocationsKbn = "1";
      return new Promise((resolve, reject) => {
        this.$httpClient
          //TODO 拠点マスタ取得のPC側実装後はそちらに合わせる
          .secureGet(appConfig.API_URL.MST_BIZ_BASE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let officeAddress =
                jsonData.resIdv.baseInfoList[0].address1 + jsonData.resIdv.baseInfoList[0].address2;
              this.$set(this.officeAddressMap, transportSid, officeAddress);
              resolve();
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * データ連携ボタン押下
     */
    dataLink(updStatusDiv) {
      if (this.listCheckboxBtn.length == 0) {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        this.updStatusDiv = updStatusDiv;
        this.confirmDialog.title = appConfig.DIALOG.confirm;
        this.confirmDialog.message = messsageUtil.getMessage("P-DVP-005_001_C");
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.cancelBtnFlg = true;
        this.confirmDialog.outsideClickNotCloseFlg = false;
        this.confirmDialog.okAction = this.updStatus;
      }
    },

    /**
     * 計画区分更新処理
     */
    updStatus() {
      // メッセージ初期化
      this.alertMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 計画区分更新用リクエストデータ
      const updatePlanData = {
        transportSid: "",
        planBeforeUpd: "",
        planAfterUpd: "",
        carSid: "",
      };
      // 計画区分更新用パラメータリスト初期化
      this.updateBinList = [];
      // 仮組に戻すか確定するか
      if (this.updStatusDiv == 1) {
        // 仮組に戻す場合
        updatePlanData.planAfterUpd = "0";
      } else {
        // 配車を確定する場合
        updatePlanData.planAfterUpd = "1";
      }

      for (var i = 0; i < this.listCheckboxBtn.length; i++) {
        if (this.listCheckboxBtn[i].dispatchBinList != null) {
          for (var j = 0; j < this.listCheckboxBtn[i].dispatchBinList.length; j++) {
            var data = Object.assign({}, updatePlanData);
            data.transportSid = this.listCheckboxBtn[i].dispatchBinList[j].transportSid;
            data.planBeforeUpd = this.listCheckboxBtn[i].dispatchBinList[j].planOnFlg;
            data.carSid = this.listCheckboxBtn[i].carSid;
            this.updateBinList.push(data);
          }
        }
      }
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_001; // 画面ID
      body.reqIdv.dispatchPlanUpdList = this.updateBinList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // ここがGETとの違い。APIの接続方法としては同じ
          .securePost(appConfig.API_URL.BIZ_DISPATCH_PLAN_UPD, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.confirmDialog.message = messsageUtil.getMessage("P-DVP-005_002_S");
              this.confirmDialog.title = appConfig.DIALOG.title;
              this.confirmDialog.isOpen = true;
              this.confirmDialog.cancelBtnFlg = false;
              this.confirmDialog.outsideClickNotCloseFlg = true;
              this.confirmDialog.okAction = this.getApiList;
              this.loadingCounter = 0;
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex.message;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 配車表出力
     */
    outputVehicleTable() {
      // 単項目チェック
      let inputCheckResult = this.$refs.form.validate();
      if (!inputCheckResult) {
        return;
      }

      // 入力チェック結果フラグ
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.createalertMessage = "";
      if (
        // 積載量入力チェック
        parseFloat(this.payloadMin) > parseFloat(this.payloadMax)
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      if (check != 0) {
        return;
      }

      this.confirmDialog.message = messsageUtil.getMessage("P-DVP-001_003_C");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.title = appConfig.DIALOG.confirm;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.cancelBtnFlg = true;
      this.confirmDialog.outsideClickNotCloseFlg = false;
      this.confirmDialog.okAction = this.outputVehicleTableOK;
    },
    /**
     * 配車表出力開始
     */
    outputVehicleTableOK() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 運行日
      config.params.operationDate = dateTimeHelper.convertUTC(this.operationDate);
      // 積載量下限値
      if (this.payloadMin) {
        config.params.payloadMin = this.payloadMin;
      }
      // 積載量上限値
      if (this.payloadMax) {
        config.params.payloadMax = this.payloadMax;
      }
      // 計画区分
      if (this.planOnFlgSelected) {
        config.params.planOnFlg = this.planOnFlgSelected;
      }
      // 営業所SID
      config.params.officeSid = this.officeSidSelected;

      // 取引先CD
      if (this.customerSelected) {
        config.params.supplierCd = this.customerSelected;
      }
      // 乗務員
      if (this.driverSelected) {
        config.params.userSid = this.driverSelected;
      }
      // 車両形状（大項目）
      if (this.vehicleMajorSelected) {
        config.params.vehicleShapeMajor = this.vehicleMajorSelected;
      }
      // 車両形状（小項目）
      if (this.vehicleMinorSelected) {
        config.params.vehicleShapeMinor = this.vehicleMinorSelected;
      }
      // 集配幹線フラグ
      if (this.pickupDeliveryTrunkFlgSelected) {
        config.params.pickupDeliveryTrunkFlg = this.pickupDeliveryTrunkFlgSelected;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(配車表出力)
          .securePost(appConfig.API_URL.BIZ_DISPATCH_VEHICLE_TABLE, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 配車登録画面への遷移
     */
    openRegisterDispatch() {
      if (this.listCheckboxBtn.length == 0) {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          if (this.listCheckboxBtn[i].dispatchBinList != null) {
            for (var j = 0; j < this.listCheckboxBtn[i].dispatchBinList.length; j++) {
              var transportSid = this.listCheckboxBtn[i].dispatchBinList[j].transportSid;
              this.visibleBinList.push(transportSid);
            }
          }
        }
        // 配車登録画面へ渡す値を設定し遷移処理
        this.$router.push({
          name: appConfig.SCREEN_ID.P_DVP_002,
          query: {
            // 便リスト
            visibleBinList: this.visibleBinList,
            operationDate: this.operationDate,
            pickupDeliveryTrunkFlg: this.pickupDeliveryTrunkFlgSelected,
          },
        });
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.changeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの社号を取得
        let inputListOutlist = this.inputList[index].carShago;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.changeListCheckbox.length; i++) {
          if (this.changeListCheckbox[i].carShago != inputListOutlist) {
            this.listCheckboxBtn.push(this.changeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.changeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.changeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
      // 全選択チェックボックスとチェックボックスの整合性を合わせる
      if (this.inputList.length != this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が異なる場合
        this.isAllSelected = false;
      } else if (this.inputList.length == this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が一致する場合
        this.isAllSelected = true;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 運行日付入力
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.operationDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.operationDateCal = null;
      }
    },

    /**
     * 運行日付を1日遡る
     */
    prevDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = toDate;
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 運行日付を1日進める
     */
    nextDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSidSelected) {
          return this.officeList[i].name;
        }
      }
    },
    /**
     * 計画区分リストボックス変換
     */
    setPlanOnFlg() {
      for (var i = 0; i < this.planOnFlgList.length; i++) {
        if (this.planOnFlgList[i].value == this.planOnFlgSelected) {
          return this.planOnFlgList[i].name;
        }
      }
    },
    /**
     * 取引先リストボックス取得処理
     */
    setCustomer() {
      for (var i = 0; i < this.customerList.length; i++) {
        if (this.customerList[i].value == this.customerSelected) {
          return this.customerList[i].name;
        }
      }
    },
    /**
     * 乗務員リストボックス取得処理
     */
    setDriver() {
      for (var i = 0; i < this.driverList.length; i++) {
        if (this.driverList[i].value == this.driverSelected) {
          return this.driverList[i].name;
        }
      }
    },
    /**
     * 車両リストボックス(大)取得処理
     */
    setVehicleMajor() {
      for (var i = 0; i < this.vehicleMajorList.length; i++) {
        if (this.vehicleMajorList[i].value == this.vehicleMajorSelected) {
          return this.vehicleMajorList[i].name;
        }
      }
    },
    /**
     * 車両リストボックス(小)取得処理
     */
    setVehicleMinor() {
      for (var i = 0; i < this.vehicleMinorList.length; i++) {
        if (this.vehicleMinorList[i].value == this.vehicleMinorSelected) {
          return this.vehicleMinorList[i].name;
        }
      }
    },
    /**
     * 集配幹線フラグリストボックス取得処理
     */
    setPickupDeliveryTrunkFlg() {
      for (var i = 0; i < this.pickupDeliveryTrunkFlgList.length; i++) {
        if (this.pickupDeliveryTrunkFlgList[i].value == this.pickupDeliveryTrunkFlgSelected) {
          return this.pickupDeliveryTrunkFlgList[i].name;
        }
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.title = appConfig.DIALOG.confirm;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.cancelBtnFlg = true;
      this.confirmDialog.outsideClickNotCloseFlg = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    waypoints(dispatchedFreightDtl) {
      const waypoints = [];

      for (const freight of dispatchedFreightDtl ?? []) {
        let waypoint = "";
        if (freight.address) {
          waypoint = freight.address;
        }
        if (freight.pickupDeliveryPoint) {
          waypoint = `${freight.pickupDeliveryPoint}, ${waypoint}`;
        } else if (waypoint === "") {
          waypoint = freight.area;
        }
        waypoints.push(waypoint);
      }
      return waypoints;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.operationDate = "";
        this.operationDateCal = "";
        this.payloadMin = "";
        this.payloadMax = "";
        this.planOnFlgSelected = "";
        this.officeSidSelected = "";
        this.customerSelected = "";
        this.driverSelected = "";
        this.vehicleMajorSelected = "";
        this.vehicleMinorSelected = "";
        this.pickupDeliveryTrunkFlgSelected = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.operationDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },

    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SHP_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false, false);
        }
      },
      deep: true,
    },
    // 取引先の入力
    customerInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (this.customerSelected && val.indexOf(this.customerSelected) == 0) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.search-row {
  padding-left: 0.7rem;
}

.txt-center {
  margin: auto;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.transport-info-box {
  width: 24%;
  height: 70px;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-right: 1%;
  background-color: #c8e6c9;
  border: 2px;
  float: left;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

//データテーブル
#listData {
  overflow-x: scroll;
  &.v-data-table--fixed-header ::v-deep {
    th:nth-child(4) {
      border-right: 3px lightgrey;
      border-right-style: solid;
    }

    //テーブルデータ部分
    td:nth-child(4) {
      border-right: 3px lightgrey;
      border-right-style: solid;
    }
    td:nth-child(5) {
      padding-left: 0.7%;
      padding-right: 0%;
    }
  }
}

.tooltip-content ::v-deep {
  visibility: hidden;
  width: 250px; /* ツールチップの幅 */
  max-height: 300px; /* ツールチップの最大高さ */
  overflow: auto; /* コンテンツが溢れる場合はスクロールバーを表示 */
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 14px;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
  position: absolute;
  left: 0;
}

.transport-info-box:hover .tooltip-content {
  visibility: visible;
}

.map-icon {
  position: absolute;
  right: 0;
}
</style>
