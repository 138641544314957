<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_registerVehicleList" />
      <!-- 登録車両一覧画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 運行日 -->
            <div class="search-autocomplete-supplier first-search-item">
              <div class="date-style">
                <v-text-field
                  outlined
                  dense
                  v-model="operationDate"
                  class="txt-single date-style"
                  :label="$t('label.lbl_operationDate')"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd, rules.required]"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="operationDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管轄営業所-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="jurisdictionOffice"
                :items="jurisdictionOfficeList"
                :label="$t('label.lbl_jurisdictionOffice')"
                class="txt-single"
                :hint="getJurisdictionOffice"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管轄子会社-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="jurisdictionChildComp"
                :items="jurisdictionChildCompList"
                :label="$t('label.lbl_jurisdictionChildComp')"
                class="txt-single"
                :hint="getJurisdictionChildComp"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 社号-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="driverName"
                :items="driverNameList"
                :label="$t('label.lbl_driverName')"
                class="txt-single"
                :hint="getDriverName"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 利用営業所-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="availableOffice"
                :items="availableOfficeList"
                :label="$t('label.lbl_useOffice')"
                class="txt-single"
                :hint="getAvailableOffice"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="other-btn"
                  @click="getEmptyVehicleList()"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>

              <!-- 複写登録/削除/新規登録 -->
              <div style="float: right; display: flex">
                <!-- 複写先運行日 -->
                <div class="date-style">
                  <v-text-field
                    outlined
                    dense
                    v-model="copyToOperationDate"
                    class="txt-single date-style"
                    :label="$t('label.lbl_copyToOperationDate')"
                    @change="changeCopyToDate"
                    :rules="[rules.yyyymmdd, rules.required]"
                    clear-icon="mdi-close-circle"
                    clearable
                    hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="calender-btn-area">
                  <v-menu
                    v-model="copyToDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="copyToOperationDateCal"
                      @input="copyToDateMenu = false"
                    />
                  </v-menu>
                  <v-btn
                    id="btn-yesterday"
                    class="day-ctrl-btn"
                    @click="preCopyToDateOfAchieveTo()"
                    text
                    x-small
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    id="btn-tomorow"
                    class="day-ctrl-btn"
                    @click="nextCopyToDateOfAchieveTo()"
                    text
                    x-small
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
                <!-- 空白 -->
                <span class="item-spacer">&nbsp;</span>
                <!-- 複写登録ボタン -->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_copyRegist')"
                  >{{ $t("btn.btn_copyRegist") }}
                </v-btn>
                <!-- 削除ボタン -->
                <v-btn color="primary" class="other-btn" @click="viewConfirmDialog('btn_delete')"
                  >{{ $t("btn.btn_delete") }}
                </v-btn>
                <!-- 新規登録ボタン -->
                <v-btn color="primary" class="other-btn" @click="viewConfirmDialog('btn_iniRegist')"
                  >{{ $t("btn.btn_iniRegist") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            :hide-default-footer="true"
            height="511px"
          >
            <!-- ヘッダーチェックボックス-->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template>
            <!-- 利用営業所状況 -->
            <template v-slot:[`header.availableOfficeStatus`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 80px; text-align: center">{{ $t("label.lbl_Situation") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 車両形状大項目 -->
            <template v-slot:[`header.vehicleShapeMajor`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 65px; text-align: center">{{ $t("label.lbl_major") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 車両形状小項目 -->
            <template v-slot:[`header.vehicleShapeMinor`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 65px; text-align: center">{{ $t("label.lbl_minor") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                :disabled="!item.isEmpty"
                @click="checkList(item, index)"
                style="transform: scale(2)"
              />
            </template>
            <!-- 社号 -->
            <template v-slot:[`item.driverName`]="{ item }">
              <div class="listNo-style">{{ item.vehicleCd }}</div>
              <div class="listNo-style">{{ "(" + item.vehicleShape + ")" }}</div>
            </template>
            <!-- 管轄営業所 -->
            <template v-slot:[`item.jurisdictionOffice`]="{ item }">
              <div class="listNo-style">{{ item.jurisdictionOffice }}</div>
            </template>
            <!-- 管轄子会社 -->
            <template v-slot:[`item.jurisdictionChildComp`]="{ item }">
              <div class="listNo-style">{{ item.jurisdictionChildComp }}</div>
            </template>
            <!-- 利用営業所状況 -->
            <template v-slot:[`item.availableOfficeStatus`]="{ item }">
              <div class="listNo-style">{{ item.availableOfficeStatus }}</div>
            </template>
            <!-- 最大積載重量 -->
            <template v-slot:[`item.maxLoadingWeight`]="{ item }">
              <div class="align-right">{{ item.maxLoadingWeight }}</div>
            </template>
            <!-- ナンバー -->
            <template v-slot:[`item.licenseNumber`]="{ item }">
              <div class="listNo-style">{{ item.licenseNumber }}</div>
            </template>
            <!-- 車両形状大項目 -->
            <template v-slot:[`item.vehicleShapeMajor`]="{ item }">
              <div class="listNo-style">{{ item.vehicleShapeMajor }}</div>
            </template>
            <!-- 車両形状小項目 -->
            <template v-slot:[`item.vehicleShapeMinor`]="{ item }">
              <div class="listNo-style">{{ item.vehicleShapeMinor }}</div>
            </template>
            <!-- 備考 -->
            <template v-slot:[`item.remarks`]="{ item }">
              <div class="listNo-style">{{ item.remarks }}</div>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <!-- 詳細入力モーダル -->
        <v-dialog v-model="isOpenDetailInputDialog" :max-width="1300" persistent>
          <detailInputDialog
            :isOpenDetailDialog.sync="isOpenDetailInputDialog"
            :editItem="editItem"
            v-on:child-event="parentMethod"
          />
        </v-dialog>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
        <v-dialog v-model="alertDialog" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ message }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="alertCancel"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
export default {
  name: appConfig.SCREEN_ID.P_DVP_005,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 初期データ
    defaultData: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 運行日
    operationDate: "",
    // 運行日カレンダー
    operationDateCal: "",
    // 管轄営業所
    jurisdictionOffice: "S000001631",
    // 管轄営業所リスト
    jurisdictionOfficeList: [],
    // 管轄子会社
    jurisdictionChildComp: "",
    // 管轄子会社リスト
    jurisdictionChildCompList: [],
    // 社号
    driverName: "",
    // 社号リスト
    driverNameList: [],
    // 利用営業所
    availableOffice: "04",
    // 利用営業所リスト
    availableOfficeList: [
      {
        value: "01",
        text: "予定あり",
        name: "予定あり",
      },
      {
        value: "02",
        text: "予定なし",
        name: "予定なし",
      },
      {
        value: "03",
        text: "利用不可",
        name: "利用不可",
      },
      {
        value: "04",
        text: "全て",
        name: "全て",
      },
    ],
    // 複写先運行日
    copyToOperationDate: "",
    // 複写先運行日カレンダー
    copyToOperationDateCal: "",
    // 表示用リスト
    inputList: [],
    copyInputList: [],
    tmpList: [],
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 編集用チェックボックス
    changeListCheckbox: [],
    listCheckboxBtn: [],
    //チェック行インデックス
    checkIndex: -1,
    isOpenDetailInputDialog: false,
    editItem: {},
    // 警告ダイアログ
    alertDialog: false,
    message: "",
    // 車両
    vehiclesSelected: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 必須チェック
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
        }
        if (value === "") {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
    },
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: i18n.tc("lbl_select"),
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 社号
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driverName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 管轄営業所
      {
        text: i18n.tc("label.lbl_jurisdictionOffice"),
        value: "jurisdictionOffice",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 管轄子会社
      {
        text: i18n.tc("label.lbl_jurisdictionChildComp"),
        value: "jurisdictionChildComp",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 利用営業所状況
      {
        text: i18n.tc("label.lbl_useOffice"),
        value: "availableOfficeStatus",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 最大積載重量
      {
        text: i18n.tc("label.lbl_maxLoadingWeightKg"),
        value: "maxLoadingWeight",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // ナンバー
      {
        text: i18n.tc("label.lbl_licenseNumber"),
        value: "licenseNumber",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 車両形状大項目
      {
        text: i18n.tc("label.lbl_vehicleShape"),
        value: "vehicleShapeMajor",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 車両形状小項目
      {
        text: i18n.tc("label.lbl_vehicleShape"),
        value: "vehicleShapeMinor",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "inputRemarks",
        width: "15%",
        align: "left",
        sortable: false,
      },
    ],
    detailDialogData: {},
    costInfoList: [],
    vehicleList: [],
    dateMenu: false,
    copyToDateMenu: false,
  }),
  methods: {
    // 検索ボタン
    search(isInit) {
      if (this.$refs.form.validate() || isInit) {
        this.getCostInfoList();
      }
    },

    // 車両API接続
    getvehicleList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 車両
      const gVehicleList = getParameter.getVehicleMst(this.vehiclesSelected);
      Promise.all([gVehicleList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.driverNameList = result[0];
          // 取得したリストのテキスト表示を社号（形状）に変換
          this.driverNameList.forEach((v) => {
            v.text = `${v.name}` + `（${v.vehicleShape}）`;
          });
          // 先頭に空の配列を追加
          // this.driverNameList.unshift({
          //   text: "",
          //   value: "",
          //   name: "",
          //   model: "",
          //   weight: "",
          //   length: "",
          //   width: "",
          //   height: "",
          //   payload: "",
          // });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 運行日
      this.operationDate = dateTimeHelper.convertJST();
      this.operationDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 複写先運行日
      let date = new Date(this.operationDate);
      this.copyToOperationDate = dateTimeHelper.convertJST();
      this.copyToOperationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      // 営業所情報取得
      this.getOfficeInfo();
      // 社号情報取得
      this.vehiclesSelected = null;
      this.getvehicleList();
      // コード情報取得
      this.getCodeMst();
      // 一覧を表示
      this.getEmptyVehicleList();
      // 戻る遷移の場合
      // 車両リスト
      if (
        this.$route.params.list_vehicleSid ||
        ((this.$route.params.list_vehicleSid == null || this.$route.params.list_vehicleSid == "") &&
          this.$route.params.vehicleSid != null)
      ) {
        this.vehiclesSelected = this.$route.params.list_vehicleSid;
        // 一覧を表示
        this.getCostInfoList();
      }
    },

    /**
     * 営業所情報取得
     */
    getOfficeInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const params = {};
      // 拠点管理種別（2=営業所を取得）
      params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      const officeList = getParameter.getTmsBizBaseMst(params);
      Promise.all([officeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.jurisdictionOfficeList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * コードマスタ情報取得
     */
    getCodeMst() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // 管轄子会社
      const jurisdictionChildCompList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.SUBSIDIARY_CODE_DIV,
      });
      Promise.all([jurisdictionChildCompList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.jurisdictionChildCompList = result[0];
          // ドロップダウンの表示をコード(コード名)になるように設定
          this.jurisdictionChildCompList.forEach((v) => {
            v.text = `${v.value}` + `（${v.name}）`;
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 空車一覧取得
     */
    getEmptyVehicleList() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      this.inputList.splice(0);
      this.tmpList.splice(0);
      const list = [
        {
          vehicleSid: "0024080101",
          vehicleCd: "FV6252CR01",
          vehicleShape: "中型ルーフアップ",
          jurisdictionOfficeSid: "S000001631",
          jurisdictionOffice: "日高営業所",
          jurisdictionChildCompCd: "",
          jurisdictionChildComp: "",
          availableOffice: "日高営業所",
          availableOfficeStatus: "日高、柏原",
          maxLoadingWeight: commonFunction.getDelimiter("8000", 9),
          licenseNumber: "XX-XX",
          vehicleShapeMajor: "中型",
          vehicleShapeMinor: "ルーフアップ",
          inputRemarks: "XXXXXXXXXXX",
          isEmpty: true,
          check: false,
        },
        {
          vehicleSid: "1615",
          vehicleCd: "M777CR",
          vehicleShape: "大型箱型",
          jurisdictionOfficeSid: "S000001631",
          jurisdictionOffice: "日高営業所",
          jurisdictionChildCompCd: "01",
          jurisdictionChildComp: "狭山日梱",
          availableOffice: "日高営業所",
          availableOfficeStatus: "日高",
          maxLoadingWeight: commonFunction.getDelimiter("10000", 9),
          licenseNumber: "XX-XX",
          vehicleShapeMajor: "大型",
          vehicleShapeMinor: "箱型",
          inputRemarks: "XXXXXXXXXXX",
          isEmpty: true,
          check: false,
        },
        {
          vehicleSid: "1615",
          vehicleCd: "M256CR",
          vehicleShape: "中型セミトレーラー",
          jurisdictionOfficeSid: "S000001631",
          jurisdictionOffice: "日高営業所",
          jurisdictionChildCompCd: "01",
          jurisdictionChildComp: "狭山日梱",
          availableOffice: "日高営業所",
          availableOfficeStatus: "日高",
          maxLoadingWeight: commonFunction.getDelimiter("10000", 9),
          licenseNumber: "XX-XX",
          vehicleShapeMajor: "中型",
          vehicleShapeMinor: "セミトレーラー",
          inputRemarks: "XXXXXXXXXXX",
          isEmpty: true,
          check: false,
        },
        {
          vehicleSid: "0002407003",
          vehicleCd: "M777CRE",
          vehicleShape: "大型コンテナ専用車",
          jurisdictionOfficeSid: "S000001631",
          jurisdictionOffice: "日高営業所",
          jurisdictionChildCompCd: "01",
          jurisdictionChildComp: "狭山日梱",
          availableOffice: "日高営業所",
          availableOfficeStatus: "日高",
          maxLoadingWeight: commonFunction.getDelimiter("10000", 9),
          licenseNumber: "XX-XX",
          vehicleShapeMajor: "大型",
          vehicleShapeMinor: "コンテナ専用車",
          inputRemarks: "XXXXXXXXXXX",
          isEmpty: true,
          check: false,
        },
      ];
      this.tmpList = list;
      // 検索条件でリストの表示内容を変更する
      // 管轄営業所
      if (this.jurisdictionOffice) {
        this.tmpList = list.filter((v) => {
          return v.jurisdictionOfficeSid == this.jurisdictionOffice;
        });
        if (this.isListEmpty(this.tmpList)) {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          return;
        }
      }
      // 管轄子会社コード
      if (this.jurisdictionChildComp) {
        if (this.tmpList.length != 0) {
          this.tmpList = this.tmpList.filter((v) => {
            return v.jurisdictionChildCompCd == this.jurisdictionChildComp;
          });
        } else {
          this.tmpList = list.filter((v) => {
            return v.jurisdictionChildCompCd == this.jurisdictionChildComp;
          });
        }

        if (this.isListEmpty(this.tmpList)) {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          return;
        }
      }

      // 社号
      if (this.driverName) {
        if (this.tmpList.length != 0) {
          this.tmpList = this.tmpList.filter((v) => {
            return v.vehicleSid == this.driverName;
          });
        } else {
          this.tmpList = list.filter((v) => {
            return v.vehicleSid == this.driverName;
          });
        }

        if (this.isListEmpty(this.tmpList)) {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          return;
        }
      }

      // 利用営業所
      if (this.availableOffice) {
        if (this.tmpList.length != 0) {
          if (this.availableOffice == "01") {
            this.tmpList = this.tmpList.filter((v) => {
              return !v.isEmpty && v.availableOffice != "利用不可";
            });
          } else if (this.availableOffice == "02") {
            this.tmpList = this.tmpList.filter((v) => {
              return v.isEmpty;
            });
          } else if (this.availableOffice == "03") {
            this.tmpList = this.tmpList.filter((v) => {
              return v.availableOffice == "利用不可";
            });
          }
        } else {
          if (this.availableOffice == "01") {
            this.tmpList = list.filter((v) => {
              return !v.isEmpty && v.availableOffice != "利用不可";
            });
          } else if (this.availableOffice == "02") {
            this.tmpList = list.filter((v) => {
              return v.isEmpty;
            });
          } else if (this.availableOffice == "03") {
            this.tmpList = list.filter((v) => {
              return v.availableOffice == "利用不可";
            });
          }
        }
        if (this.isListEmpty(this.tmpList)) {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          return;
        }
      }
      this.inputList = this.tmpList;
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    isListEmpty(list) {
      if (list.length == 0) {
        return true;
      }
      return false;
    },

    /**
     * 備考入力モーダル表示処理
     */
    openDetailInputDialog(item) {
      this.isOpenDetailInputDialog = true;
      this.editItem = item;
    },

    /**
     * 本画面と備考入力ダイアログとのデータ受け渡しを行う関数
     */
    parentMethod: async function (operation, item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1, item);
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      //const arg = `${this.copyInputList.find((v) => v.value == this.copyTargetVehicle).text}`;
      const arg = this.copyToOperationDate;
      switch (button) {
        case "btn_copyRegist":
          // １つも明細がチェックされていない場合、警告ダイアログを表示
          if (!this.isCheck()) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }
          if (arg == "" || arg == null) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-DST-002_001_E", [
              i18n.tc("label.lbl_copyToOperationDate"),
            ]);
            return;
          }
          message = messsageUtil.getMessage("P_DVP_004_001_C", [arg]);
          action = this.copyRegist;
          break;
        case "btn_delete":
          // １つも明細がチェックされていない場合、警告ダイアログを表示
          if (!this.isCheck()) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }
          isValidationRequired = false;
          message = messsageUtil.getMessage("P_DVP_004_002_C");
          action = this.delete;
          break;
        case "btn_iniRegist":
          // 登録画面へ遷移
          this.$router.push({
            name: appConfig.SCREEN_ID.P_DVP_005,
            params: {},
          });
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // let isValid = this.$refs.editedList.validate();
        // if (!isValid) {
        //   return;
        // }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },

    /**
     * 複写登録処理
     */
    copyRegist() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = false;
      this.infoDialog.outsideClickNotCloseFlg = true;
      this.infoDialog.firstPageFlag = false;
    },

    /**
     * 複写登録処理
     */
    delete() {
      this.inputList.splice(this.checkIndex, 1);
      this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = false;
      this.infoDialog.outsideClickNotCloseFlg = true;
      this.infoDialog.firstPageFlag = false;
    },

    /**
     * 明細行を選択しているかの判定処理
     */
    isCheck() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.check);
      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        return false;
      }
      return true;
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        // 編集用リストを初期化
        this.changeListCheckbox = [];
        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].isEmpty) {
            this.inputList[i].check = true;
            this.changeListCheckbox.push(this.inputList[i]);
            this.listCheckboxBtn.push(this.inputList[i]);
          }
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          if (this.inputList[j].isEmpty) {
            this.inputList[j].check = false;
            // 編集用リストを初期化
            this.changeListCheckbox = [];
            // 画面遷移用リストを初期化
            this.listCheckboxBtn = [];
          }
        }
        this.isAllSelected = false;
      }
    },
    /**
     * 一覧チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        //チェックした行のインデックス
        this.checkIndex = index;

        // 編集用リスト
        this.changeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの社号を取得
        let inputListOutlist = this.inputList[index].driverName;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.changeListCheckbox.length; i++) {
          if (this.changeListCheckbox[i].driverName != inputListOutlist) {
            this.listCheckboxBtn.push(this.changeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.changeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.changeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
      // 全選択チェックボックスとチェックボックスの整合性を合わせる
      if (this.inputList.length != this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が異なる場合
        this.isAllSelected = false;
      } else if (this.inputList.length == this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が一致する場合
        this.isAllSelected = true;
      }
    },
    /**
     * 明細の利用営業所が利用不可かどうか判定
     */
    isAvailableOffice(office) {
      if (office == "利用不可") {
        return true;
      }
      return false;
    },
    // ヘッダー関連
    // カレンダー関連
    /** 運行日付をー１日 */
    preDateOfAchieveTo() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /** 運行日付を+１日 */
    nextDateOfAchieveTo() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /** 運行日付をー１日 */
    preCopyToDateOfAchieveTo() {
      if (this.copyToOperationDateCal == null) {
        return;
      }
      let date = new Date(this.copyToOperationDateCal);
      this.copyToOperationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /** 運行日付を+１日 */
    nextCopyToDateOfAchieveTo() {
      if (this.copyToOperationDateCal == null) {
        return;
      }
      let date = new Date(this.copyToOperationDateCal);
      this.copyToOperationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /** 日付入力 */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.operationDateCal = dateTimeHelper.dateCalc(strDt);
        this.operationDate = strDt;
      } else {
        this.operationDateCal = null;
      }
    },

    /** 日付入力 */
    changeCopyToDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.copyToOperationDateCal = dateTimeHelper.dateCalc(strDt);
        this.operationDate = strDt;
      } else {
        this.copyToOperationDateCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。*/
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
  },

  computed: {
    // 管轄営業所ヒント取得
    getJurisdictionOffice() {
      let jurisdictionOffice = this.jurisdictionOfficeList.find(
        (v) => v.value == this.jurisdictionOffice
      );
      if (jurisdictionOffice) {
        return jurisdictionOffice.name;
      }
      return "";
    },
    // 管轄子会社ヒント取得
    getJurisdictionChildComp() {
      let jurisdictionChildComp = this.jurisdictionChildCompList.find(
        (v) => v.value == this.jurisdictionChildComp
      );
      if (jurisdictionChildComp) {
        return jurisdictionChildComp.name;
      }
      return "";
    },
    // 社号ヒント取得
    getDriverName() {
      let driverName = this.driverNameList.find((v) => v.value == this.driverName);
      if (driverName) {
        return driverName.name;
      }
      return "";
    },
    getAvailableOffice() {
      let availableOffice = this.availableOfficeList.find((v) => v.value == this.availableOffice);
      if (availableOffice) {
        return availableOffice.name;
      }
      return "";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },
    copyToOperationDateCal: {
      handler(val) {
        this.copyToOperationDate = this.formatDate(val);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}

.toatlNum {
  margin-left: 10px;
}

.v-data-table {
  white-space: pre-line;
}

.align-cost-right {
  text-align: right;
  width: "5%";
}

.align-rate-right {
  text-align: right;
  width: "3%";
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
</style>
