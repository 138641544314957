<template>
  <v-sheet color="white" height="100vh" class="overflow-hidden">
    <v-sheet class="overflow-hidden" height="100%">
      <NavBar tittle="label.lbl_vehicleDispatch" />
      <Loading :loadingCounter="loadingCounter" />
      <v-sheet class="overflow-hidden" height="calc(100% - 48px)">
        <v-container fluid class="pa-1 grey lighten-3" style="height: 100%">
          <v-row no-gutters class="fill-height">
            <v-col cols="12" class="d-flex justify-space-between fill-height">
              <!-- 車両一覧 -->
              <v-sheet
                width="250"
                elevation="5"
                rounded="lg"
                height="100%"
                class="pa-1 flex-grow-0 flex-shrink-1"
              >
                <v-row dense class="fill-height">
                  <v-col cols="12" class="d-flex flex-column fill-height">
                    <v-row dense class="flex-grow-0 flex-shrink-1">
                      <v-col cols="12">
                        <DatePicker
                          :key="'DatePicker' + datePickerKey"
                          :value="operationDate"
                          @input="changeOperationDate"
                          :label="$t('label.lbl_operationDate')"
                          :required="true"
                          :clearable="false"
                        />
                        <!-- 運行日変更時のダイアログ -->
                        <ConfirmDialog
                          :isShow.sync="showOperationDateChangedDialog"
                          :screenFlag="true"
                          :okBtnTxt="$t('btn.btn_change')"
                          :message="operationDateChangedDialogMessage"
                          :okAction="operationDateChangedDialogOkAction"
                          :cancelAction="operationDateChangedDialogCancelAction"
                        ></ConfirmDialog>
                      </v-col>
                    </v-row>
                    <v-row dense justify="space-around" class="flex-grow-0 flex-shrink-1">
                      <v-col cols="6">
                        <v-select
                          :value="vehicleSort.vehicleSort1"
                          @change="
                            (value) => (vehicleSort = { ...vehicleSort, vehicleSort1: value })
                          "
                          :items="vehicleSortItems"
                          :label="'第1ソート'"
                          dense
                          clearable
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :value="vehicleSort.vehicleSort2"
                          @change="
                            (value) => (vehicleSort = { ...vehicleSort, vehicleSort2: value })
                          "
                          :items="vehicleSortItems"
                          :label="'第2ソート'"
                          dense
                          clearable
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1 mb-2"></v-divider>
                    <v-row dense class="flex-grow-1" style="max-height: calc(100% - 140px)">
                      <v-col
                        cols="12"
                        class="d-flex flex-column flex-nowrap overflow-y-auto fill-height"
                      >
                        <VehicleItem
                          v-for="vehicle in dispatchVehicles"
                          :key="vehicle.carSid"
                          :dispatchVehicle="vehicle"
                          class="mb-1"
                          @add-bin="addBin"
                          @visiable-bin="visiableBin"
                        ></VehicleItem>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
              <!-- 配車エリア -->
              <v-sheet
                outlined
                height="100%"
                max-width="calc(100% - 250px - 330px)"
                rounded="md"
                color="transparent"
                class="mx-1 flex-grow-1 flex-shrink-0 d-flex flex-column"
              >
                <v-row
                  no-gutters
                  style="height: calc(100% - 50px); width: 100%"
                  class="flex-grow-1 flex-shrink-0 overflow-x-auto overflow-y-hidden"
                >
                  <v-col
                    cols="12"
                    style="height: 100%; width: 100%"
                    class="d-flex flex-nowrap pb-1"
                  >
                    <template v-for="(vehicle, vehicleIndex) in dispatchVehicles">
                      <BinItem
                        width="calc(100% / 4 - 16px)"
                        height="calc(100% - 4px)"
                        v-for="(binItem, binIndex) in vehicle.dispatchBinList ?? []"
                        :key="'bi' + binItem.transportSid"
                        :officeAddress="myOfficeAddress"
                        :operationDate="operationDate"
                        :binItem="{
                          carSid: vehicle.carSid,
                          carShape: vehicle.carShape,
                          carShago: vehicle.carShago,
                          carPayload: vehicle.carPayload,
                          binNo: binIndex + 1,
                          ...binItem,
                        }"
                        :drivers="drivers"
                        class="ma-1 flex-shrink-0"
                        @delete-bin="deleteBin"
                        @change-driver="changeDriver"
                        @change-from="changeFrom"
                        @change-to="changeTo"
                        @change-remarks="changeRemarks"
                        @change-freight="changeFreightOrder"
                        @click:pair="dispatchPairFreights(vehicle.carSid, binItem.transportSid)"
                        v-show="binItem.visible"
                      >
                        <SortableWrapper
                          :option="{
                            ...dragOptions,
                            disabled: (binItem.binStatusDiv ?? '0') !== '0',
                          }"
                          class="overflow-y-auto overflow-x-hidden pa-1"
                          :label="'配車リスト' + binItem.transportSid"
                          :value="binItem.dispatchedFreightDtl"
                          @input="
                            (newItems) =>
                              $set(
                                dispatchVehicles[vehicleIndex].dispatchBinList[binIndex],
                                'dispatchedFreightDtl',
                                newItems
                              )
                          "
                        >
                          <FreightItem
                            v-for="freight in binItem.dispatchedFreightDtl"
                            :key="freight.pickupDeliveryDiv + '_' + freight.packingSid"
                            :freight="freight"
                            :inputDisableFlg="
                              (binItem.binStatusDiv == '1' || binItem.binStatusDiv == '2') ?? true
                            "
                            class="mb-1"
                            @input="deliveryDateTime"
                          ></FreightItem>
                        </SortableWrapper>
                      </BinItem>
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="flex-shrink-1 flex-grow-0">
                  <v-col cols="12" class="text-right">
                    <v-btn :disabled="isBinChange" color="primary" elevation="2" @click="register">
                      仮組み登録
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <!-- 未配車貨物 -->
              <v-sheet
                width="320"
                elevation="5"
                rounded="lg"
                class="pa-1 flex-grow-0 flex-shrink-1 undispatch"
              >
                <v-row dense class="pt-4">
                  <v-col cols="12" class="text-caption filter-text">
                    <span class="font-weight-bold mr-1">並べ替え</span>
                    {{ sortText }}
                  </v-col>
                  <v-col cols="12" class="text-caption filter-text">
                    <span class="font-weight-bold mr-1">絞り込み</span>
                    {{ filterText }}
                  </v-col>
                </v-row>
                <v-row dense class="justify-center">
                  <SearchCondition
                    :openLabel="'検索条件を設定する'"
                    :dialogTitle="`検索条件の追加・変更`"
                    :filters="filters"
                    :sortItems="freightSortItems"
                    @change:condition="filterItems = $event"
                    @change:sort="sortItems = $event"
                    @click="filterUndispatchFreights"
                  >
                  </SearchCondition>
                </v-row>
                <v-row dense>
                  <v-col cols="12" class="text-center text-caption">
                    <span> 未配車貨物:</span> {{ undispatchFreightCount }} <span>件</span>
                  </v-col>
                </v-row>
                <v-tabs v-model="selectedUndispatchTabIndex" grow height="auto" class="mt-1">
                  <v-tab v-for="item in undispatchFreightTabs" :key="item.key">
                    {{ $t(item.label) }}
                  </v-tab>
                </v-tabs>
                <v-divider class="mt-0 mb-1"></v-divider>
                <v-row dense style="height: calc(100% - 160px)">
                  <v-col
                    cols="12"
                    class="pa-1 fill-height d-flex flex-column flex-nowrap overflow-y-auto"
                  >
                    <SortableWrapper
                      :option="dragOptions"
                      :label="'貨物リスト'"
                      v-model="undispatchedFreights"
                    >
                      <FreightItem
                        v-for="freight in undispatchedFreights"
                        :key="freight.pickupDeliveryDiv + '_' + freight.packingSid"
                        :freight="freight"
                        :undispatchFreightFlg="true"
                        class="mb-1"
                        :class="
                          displayUndisptachFreightPickupDeliveryDivs.has(freight.pickupDeliveryDiv)
                            ? ''
                            : 'd-none'
                        "
                        @input="deliveryDateTime"
                      ></FreightItem>
                    </SortableWrapper>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="showConfirmDialog"
      :title="confirmDialogOption.title"
      :message="confirmDialogOption.message"
      :redMessage="confirmDialogOption.redMessage"
      :okAction="confirmDialogOption.okAction"
      :screenFlag="confirmDialogOption.screenFlag"
      :changeFlag="confirmDialogOption.changeFlag"
      :cancelAction="confirmDialogOption.cancelAction"
      :okBtnTxt="confirmDialogOption.okBtnTxt"
      :cancelBtnTxt="confirmDialogOption.cancelBtnTxt"
      :cancelBtnFlg="confirmDialogOption.cancelBtnFlg"
      :okBtnColor="confirmDialogOption.okBtnColor"
      :isAlert="confirmDialogOption.isAlert"
      :outsideClickNotCloseFlg="confirmDialogOption.outsideClickNotCloseFlg"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isShow"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="true"
      :closeFlag="false"
    />
  </v-sheet>
</template>

<script>
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import DatePicker from "../../components/DatePicker.vue";
import NavBar from "../../components/NavBar.vue";
import VehicleItem from "./VehicleItem.vue";
import FreightItem from "./FreightItem.vue";
import BinItem from "./BinItem.vue";
import SortableWrapper from "./SortableWrapper.vue";
import Loading from "../../components/loading.vue";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonFunction } from "@/assets/scripts/js/CommonFunction.js";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import SimpleDialog from "../../components/SimpleDialog.vue";
import SearchCondition from "./SearchCondition.vue";

/** 集荷配達区分 */
const PickupDeliveryDiv = Object.freeze({
  PICKUP: "01",
  DELIVERY: "02",
});

/** 未配車貨物のタブの情報 */
const UndispatchFreightTabValue = Object.freeze({
  DELIVERY: Object.freeze({
    key: "DELIVERY",
    label: "label.lbl_tab_item_delivery",
    pickupDeliveryDivs: new Set([PickupDeliveryDiv.DELIVERY]),
    order: 1,
  }),
  PICKUP: Object.freeze({
    key: "PICKUP",
    label: "label.lbl_tab_item_pickup",
    pickupDeliveryDivs: new Set([PickupDeliveryDiv.PICKUP]),
    order: 2,
  }),
  ALL: Object.freeze({
    key: "ALL",
    label: "label.lbl_tab_item_all",
    pickupDeliveryDivs: new Set([PickupDeliveryDiv.DELIVERY, PickupDeliveryDiv.PICKUP]),
    order: 3,
  }),
});

export default {
  name: "VehicleDispatchRegister",
  components: {
    NavBar,
    DatePicker,
    VehicleItem,
    FreightItem,
    BinItem,
    SortableWrapper,
    Loading,
    SimpleDialog,
    ConfirmDialog,
    SearchCondition,
  },
  mounted() {
    const from = this.operationDateUTC;
    const fromDate = dateTimeHelper.convertJstDateTime(from);
    fromDate.setDate(fromDate.getDate() + 1); // 初期表示時の予定日の絞り込みは運行日とその次の日までに設定
    const to = dateTimeHelper.convertJST(fromDate);
    this.filterItems = {
      "scheduleDate.from": from,
      "scheduleDate.to": to,
    };
    this.init().finally(() => {
      this.loadingCounter = 0;
    });
  },
  data() {
    return {
      loadingCounter: 1,
      operationDate:
        this.$route.query?.operationDate ?? dateTimeHelper.toStringNowDate().split(" ")[0],
      datePickerKey: 1,
      showOperationDateChangedDialog: false,
      operationDateChangedDialogMessage: messsageUtil.getMessage("P-DVP-001_005_W"),
      operationDateChangedDialogOkAction: () => {},
      operationDateChangedDialogCancelAction: () => {},
      vehicleSort: {
        vehicleSort1: null,
        vehicleSortOrder1: "ASC",
        vehicleSort2: null,
        vehicleSortOrder2: "ASC",
      },

      vehicleSortItems: [
        { text: "形状", value: "carShape" },
        { text: "車号", value: "carShago" },
        { text: "積載量", value: "carPayload" },
      ],

      freightSort: {
        vehicleSort1: null,
        vehicleSortOrder1: "ASC",
        vehicleSort2: null,
        vehicleSortOrder2: "ASC",
        vehicleSort3: null,
        vehicleSortOrder3: "ASC",
        vehicleSort4: null,
        vehicleSortOrder4: "ASC",
      },
      freightSortItems: [
        { text: "方面", value: "area" },
        { text: "集配区分", value: "pickupDeliveryDiv" },
        { text: "重量", value: "totalWeight" },
        { text: "予定日時", value: "scheduleFromDate" },
      ],
      myOfficeAddress: "",
      drivers: [],
      originalDispatchVehicles: [],
      dispatchVehicles: [],
      undispatchedFreights: [],
      totalUndispatchFreightCount: 0,
      originalUndispatchFreightCount: 0,
      newTransportSidPrefix: "new_",
      newTransportSidNumber: 0,
      showConfirmDialog: false,
      confirmDialogDefaultOption: {
        title: "確認",
        message: "",
        redMessage: "",
        okAction: () => {},
        screenFlag: false,
        changeFlag: false,
        cancelAction: () => {},
        okBtnTxt: "OK",
        cancelBtnTxt: "キャンセル",
        cancelBtnFlg: true,
        okBtnColor: "",
        isAlert: false,
        outsideClickNotCloseFlg: false,
      },
      modifiedConfirmDialogOption: {},
      infoDialog: {
        isShow: false,
        message: "",
        title: "",
      },
      filter: {
        required: false,
        label: "",
        type: "",
        multi: false,
        group: false,
        values: "",
        texts: "",
        groupValues: [],
      },
      groupValue: {
        label: "",
        values: [],
        texts: "",
      },
      filters: [],
      filterItems: [],
      sortItems: [],
      isBinChange: false,
      selectedUndispatchTabIndex: 0, // タブの初期値: 配達
      undispatchFreightTabs: Object.values(UndispatchFreightTabValue).sort(
        (value1, value2) => value1.order - value2.order
      ),
      pagingMax: 9999,
    };
  },
  computed: {
    confirmDialogOption() {
      return {
        ...this.confirmDialogDefaultOption,
        ...this.modifiedConfirmDialogOption,
      };
    },
    binItems: {
      get() {
        const bins = this.dispatchVehicles
          .filter(({ dispatchBinList }) => dispatchBinList?.length > 0 ?? false)
          .flatMap((vehicle) => {
            return vehicle.dispatchBinList.map((bin) => {
              return {
                carSid: vehicle.carSid,
                carShape: vehicle.carShape,
                carShago: vehicle.carShago,
                carPayload: vehicle.carPayload,
                ...bin,
              };
            });
          });
        return bins;
      },
      set(value) {
        this.dispatchVehicles = value;
      },
    },
    operationDateUTC() {
      return dateTimeHelper.convertUTC(this.operationDate + " 00:00:00");
    },
    undispatchFreightCount() {
      const currentCount = this.undispatchedFreights?.length ?? 0;
      const currentStr = currentCount === 0 ? "0" : commonFunction.getDelimiter(currentCount);
      const total =
        this.undispatchFreightTotalCount - (this.originalUndispatchFreightCount - currentCount);
      const totalStr = total === 0 ? "0" : commonFunction.getDelimiter(total);
      return `${currentStr}/${totalStr}`;
    },
    dragOptions() {
      return {
        animation: 150,
        group: "freight",
        disabled: false,
        ghostClass: "freight-ghost",
        multiDrag: true,
        selectedClass: "selected-freight",
        dragClass: "dragging-freight",
        avoidImplicitDeselect: false,
        multiDragKey: "CTRL",
      };
    },
    displayUndisptachFreightPickupDeliveryDivs() {
      return this.undispatchFreightTabs[this.selectedUndispatchTabIndex].pickupDeliveryDivs;
    },
    sortText() {
      return this.sortItems
        .map(({ property, order }) => {
          const propName = this.freightSortItems.find((item) => item.value === property)?.text;
          const orderText =
            order === "asc"
              ? this.$t("label.lbl_ascending_order")
              : this.$t("label.lbl_descending_order");
          return `${propName}(${orderText})`;
        })
        .join(", ");
    },
    filterText() {
      const arr = [];
      for (const filter of this.filters) {
        if (filter.range === "true" || filter.range === "timeonly") {
          const from = this.filterItems[filter.name + ".from"];
          const to = this.filterItems[filter.name + ".to"];
          if (!from && !to) {
            continue;
          }
          arr.push(`${filter.label}:[${from ?? ""} ~ ${to ?? ""}]`);
          continue;
        }
        const value = this.filterItems[filter.name];
        if (!value || !value.length) {
          continue;
        }
        let values = Array.isArray(value) ? value : [value];
        if (["radio", "select", "autocomplete"].includes(filter.type)) {
          const optionValue = filter.values ?? filter.groupValues.flatMap((v) => v.values);
          values = values.map((v) => optionValue.find((ov) => ov.value === v).text);
        }
        const filterText = values.join(", ");
        arr.push(`${filter.label}:[${filterText}]`);
      }
      return arr.join(" ");
    },
  },
  watch: {
    vehicleSort: async function (newValue) {
      this.vehicleSort = newValue;

      this.loadingCounter = 1;
      await this.modifyVehicleDispatch();
      this.loadingCounter = 0;
    },
    freightSort: async function (newValue) {
      this.freightSort = newValue;

      this.loadingCounter = 1;
      await this.modifyVehicleDispatch();
      this.loadingCounter = 0;
    },
    dispatchVehicles: {
      handler: function () {
        const changeDispatchList = this.getRegiterDispatchVehicle();
        // 便に変更あれば活性、なければ非活性
        this.isBinChange = !changeDispatchList.length;
      },
      // 子要素の変更まで検知
      deep: true,
    },
  },
  methods: {
    async init() {
      try {
        const [[totalCount, filters, undispatchedFreights], dispatchVehicles, drivers, address] =
          await Promise.all([
            this.fetchUndispatchedFreightList(this.filterItems, this.sortItems),
            this.fetchDispatchVehicleSearch(
              this.operationDateUTC,
              this.vehicleSort,
              this.$route.query?.visibleBinList
            ),
            this.fetchDrivers(),
            this.getMyOfficeAddress(),
          ]);
        // 初期表示以外は表示リストは初期化する
        if (this.$route.query && this.$route.query.visibleBinList) {
          var query = Object.assign({}, this.$route.query);
          delete query.visibleBinList;
          this.$router.push({ query: query });
        }
        this.undispatchFreightTotalCount = totalCount;
        this.originalUndispatchFreightCount = undispatchedFreights?.length ?? 0;
        this.filters = this.mergeFilters(this.filters, filters);
        this.undispatchedFreights = [...undispatchedFreights];
        this.dispatchVehicles = [...dispatchVehicles];
        this.drivers = [...drivers];
        this.myOfficeAddress = address;

        // 更新時に配車情報の変更があったか判定できるように画面表示時点での配車情報を保持する
        this.originalDispatchVehicles = JSON.parse(JSON.stringify(dispatchVehicles));
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },
    async modifyVehicleDispatch() {
      try {
        const [[totalCount, filters, newUndispatchedFreights], newDispatchVehicles, drivers] =
          await Promise.all([
            this.fetchUndispatchedFreightList(this.filterItems, this.sortItems),
            this.fetchDispatchVehicleSearch(this.operationDateUTC, this.vehicleSort),
            this.fetchDrivers(),
          ]);

        const [dispatchVehicles, undispatchedFreights] = this.mergeDispatchFreights(
          newDispatchVehicles,
          newUndispatchedFreights,
          this.dispatchVehicles,
          this.undispatchedFreights,
          this.originalDispatchVehicles
        );

        this.undispatchFreightTotalCount = totalCount;
        this.originalUndispatchFreightCount = undispatchedFreights?.length ?? 0;
        this.filters = this.mergeFilters(this.filters, filters);
        this.dispatchVehicles = dispatchVehicles;
        this.undispatchedFreights = undispatchedFreights;
        this.originalDispatchVehicles = newDispatchVehicles;
        this.driver = drivers;
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },

    changeOperationDate(newValue) {
      if (this.operationDate === newValue) {
        return;
      }

      if (!dateTimeHelper.isDisplayDateFormat(newValue.replaceAll("-", "/"))) {
        // 運行日の入力欄に表示される日付を戻すため、keyを更新する
        this.datePickerKey += 1;
        return;
      }

      if (this.binChanged()) {
        this.operationDateChangedDialogOkAction = () => {
          this.operationDate = newValue;
          this.loadingCounter = 1;
          this.init().finally(() => {
            this.loadingCounter = 0;
          });
        };
        this.operationDateChangedDialogCancelAction = () => {
          // 運行日の入力欄に表示される日付を戻すため、keyを更新する
          this.datePickerKey += 1;
        };
        this.showOperationDateChangedDialog = true;
      } else {
        this.operationDate = newValue;
        this.loadingCounter = 1;
        this.init().finally(() => {
          this.loadingCounter = 0;
        });
      }
    },

    binChanged() {
      const originalBinList = this.originalDispatchVehicles.flatMap(
        ({ dispatchBinList }) => dispatchBinList ?? []
      );
      const editedBinList = this.dispatchVehicles.flatMap(
        ({ dispatchBinList }) => dispatchBinList ?? []
      );

      // 便の数が違う場合
      if (originalBinList.length !== editedBinList.length) {
        return true;
      }

      // 新規で追加された便がある場合
      if (
        editedBinList.some(({ transportSid }) =>
          transportSid.startsWith(this.newTransportSidPrefix)
        )
      ) {
        return true;
      }

      for (let i = 0; i < originalBinList.length; i++) {
        const originalBin = originalBinList[i];
        const editBin = editedBinList[i];

        // 便の順番が違う場合
        if (originalBin.transportSid !== editBin.transportSid) {
          return true;
        }

        // 便のドライバーが異なる場合
        if (originalBin.driverUserSid !== editBin.driverUserSid) {
          return true;
        }

        // 便の開始時刻が異なる場合
        if (originalBin.fromDate !== editBin.fromDate) {
          return true;
        }

        // 便の終了時刻が異なる場合
        if (originalBin.toDate !== editBin.toDate) {
          return true;
        }

        // 便の備考が異なる場合
        if (originalBin.remarks !== editBin.remarks) {
          return true;
        }

        const originalFreights = originalBin.dispatchedFreightDtl ?? [];
        const editedFreights = editBin.dispatchedFreightDtl ?? [];

        /// 便に配車されている貨物の数が違う場合
        if (originalFreights.length !== editedFreights.length) {
          return true;
        }

        // 配車されている貨物の順番が違う場合
        for (let j = 0; j < originalFreights.length; j++) {
          const originalFreight = originalFreights[j];
          const editedFreight = editedFreights[j];
          if (originalFreight.packingSid !== editedFreight.packingSid) {
            return true;
          }
        }
      }
      return false;
    },

    deliveryDateTime(deliveryDateTime) {
      const opeDate = new Date(this.operationDateUTC);

      if (deliveryDateTime && deliveryDateTime.length == 16) {
        let deliveryDateStr = deliveryDateTime.substr(0, 10);
        let deliveryDateUTC = dateTimeHelper.convertUTC(deliveryDateStr);
        // 運行日より過去日の場合、ダイアログを表示
        if (opeDate.getTime() > new Date(deliveryDateUTC).getTime()) {
          this.modifiedConfirmDialogOption = {
            message: messsageUtil.getMessage("P-DVP-001_003_W"),
            title: appConfig.DIALOG.confirm,
            screenFlag: true,
            cancelBtnFlg: false,
          };
          this.showConfirmDialog = true;
        }
      }
    },
    changeDriver(carSid, transportSid, driverSid) {
      const driver = this.drivers.find(({ driverUserSid }) => driverUserSid === driverSid);

      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      const oldVehicle = this.dispatchVehicles[vehicleIndex];
      const binIndex = oldVehicle.dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );

      const oldBinItem = oldVehicle.dispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        driverUserSid: driver?.driverUserSid ?? "",
        driverUserName: driver?.driverUserName ?? "",
      };

      const newVehicle = {
        ...oldVehicle,
        dispatchBinList: oldVehicle.dispatchBinList.toSpliced(binIndex, 1, newBinItem),
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, newVehicle);
    },
    changeFrom(carSid, transportSid, fromDateTime) {
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      const oldVehicle = this.dispatchVehicles[vehicleIndex];
      const binIndex = oldVehicle.dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );
      const oldBinItem = oldVehicle.dispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        fromDate: fromDateTime != null ? dateTimeHelper.convertUTC(fromDateTime) : "",
      };
      const newVehicle = {
        ...oldVehicle,
        dispatchBinList: oldVehicle.dispatchBinList.toSpliced(binIndex, 1, newBinItem),
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, newVehicle);
    },
    changeTo(carSid, transportSid, toDateTime) {
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      const oldVehicle = this.dispatchVehicles[vehicleIndex];
      const binIndex = oldVehicle.dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );
      const oldBinItem = oldVehicle.dispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        toDate: toDateTime != null ? dateTimeHelper.convertUTC(toDateTime) : "",
      };
      const newVehicle = {
        ...oldVehicle,
        dispatchBinList: oldVehicle.dispatchBinList.toSpliced(binIndex, 1, newBinItem),
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, newVehicle);
    },
    changeRemarks(carSid, transportSid, remarks) {
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      const oldVehicle = this.dispatchVehicles[vehicleIndex];
      const binIndex = oldVehicle.dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );
      const oldBinItem = oldVehicle.dispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        remarks: remarks ?? "",
      };
      const newVehicle = {
        ...oldVehicle,
        dispatchBinList: oldVehicle.dispatchBinList.toSpliced(binIndex, 1, newBinItem),
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, newVehicle);
    },
    changeFreightOrder(newItems, objectCarSid, objectTransportSid) {
      this.$set(
        this.dispatchVehicles
          .find(({ carSid }) => carSid === objectCarSid)
          .dispatchBinList.find(({ transportSid }) => transportSid === objectTransportSid),
        "dispatchedFreightDtl",
        newItems
      );
    },
    addBin(carSid) {
      const newTransportSid = () => {
        this.newTransportSidNumber = this.newTransportSidNumber + 1;
        return `${this.newTransportSidPrefix}${this.newTransportSidNumber}`;
      };
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      const vehicle = this.dispatchVehicles[vehicleIndex];

      const newBin = {
        transportSid: newTransportSid(),
        driverUserSid: "",
        driverUserName: "",
        fromDate: null,
        toDate: null,
        remarks: "",
        dispatchedFreightDtl: [],
        visible: true,
        binStatusDiv: "0", // 未集荷・未配達
      };
      if (!vehicle) {
        return;
      }

      const newVehicle = {
        ...vehicle,
        dispatchBinList: [...(vehicle.dispatchBinList ?? []), newBin],
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, newVehicle);
    },
    dispatchPairFreights(carSid, transportSid) {
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);
      if (vehicleIndex === -1) {
        return;
      }
      const vehicle = this.dispatchVehicles[vehicleIndex];

      const binIndex =
        vehicle.dispatchBinList?.findIndex((bin) => bin.transportSid === transportSid) ?? -1;
      if (binIndex === -1) {
        return;
      }
      const binInfo = this.dispatchVehicles[vehicleIndex].dispatchBinList[binIndex];

      const dispatchedFreights = binInfo.dispatchedFreightDtl ?? [];
      if (!dispatchedFreights.length) {
        return;
      }

      // 配車済みの貨物の荷姿SIDを取得
      const dispatchedPackingSids = dispatchedFreights.reduce(
        (set, { packingSid }) => set.add(packingSid),
        new Set()
      );

      // 未配車貨物から配車済み貨物のペア（同じ荷姿SIDの集荷だったら配達、配達だったら集荷）になる貨物を抽出
      const undispatchPairFreights = this.undispatchedFreights.filter((freight) =>
        dispatchedPackingSids.has(freight.packingSid)
      );
      if (!undispatchPairFreights.length) {
        return;
      }

      const pickupDeliveyFreightMap = Map.groupBy(
        undispatchPairFreights,
        (freight) => freight.pickupDeliveryDiv
      );

      // ペアとなる貨物のうち集荷を配車済みの貨物の先頭、配達を配車済みの貨物の最後尾に配置する
      binInfo.dispatchedFreightDtl = [
        ...(pickupDeliveyFreightMap.get(PickupDeliveryDiv.PICKUP) ?? []), // 集荷
        ...dispatchedFreights,
        ...(pickupDeliveyFreightMap.get(PickupDeliveryDiv.DELIVERY) ?? []), // 配達
      ];

      // 便情報更新
      vehicle.dispatchBinList[binIndex] = binInfo;
      this.dispatchVehicles.splice(vehicleIndex, 1, vehicle);

      // 未配車貨物からペアとして配車した貨物を削除
      this.undispatchedFreights = this.undispatchedFreights.filter(
        ({ packingSid }) => !dispatchedPackingSids.has(packingSid)
      );
    },

    visiableBin(carSid, transportSid) {
      // 車両順取得
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);

      // 便順取得
      const binIndex = this.dispatchVehicles[vehicleIndex].dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );
      // 表示を反転させる
      const binData = this.dispatchVehicles[vehicleIndex].dispatchBinList[binIndex];
      if (binData) {
        binData.visible = !binData.visible;
      }
    },

    deleteBin(carSid, transportSid) {
      const vehicleIndex = this.dispatchVehicles.findIndex((vehicle) => vehicle.carSid === carSid);

      const vehicleData = this.dispatchVehicles[vehicleIndex];

      const binIndex = this.dispatchVehicles[vehicleIndex].dispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );

      const dispatchBinList = vehicleData.dispatchBinList[binIndex];

      let message = "";
      let okAction = () => {};
      // 計画区分で表示するメッセージを分ける
      if (dispatchBinList.planOnFlg === "1") {
        message = messsageUtil.getMessage("P-DVP-001_002_W");
        okAction = () => this.deletePost(vehicleIndex, binIndex);
      } else {
        const arg = `${vehicleData.carShape} ${vehicleData.carShago} ${binIndex + 1}便`;
        message = messsageUtil.getMessage("P-DVP-001_006_W", [arg]);
        if (!dispatchBinList.transportSid.startsWith(this.newTransportSidPrefix)) {
          okAction = () => this.deletePost(vehicleIndex, binIndex);
        } else {
          okAction = () => {
            this.binDispDelete(vehicleIndex, binIndex);
            this.modifiedConfirmDialogOption = {
              title: this.$t("label.lbl_completed"),
              message: messsageUtil.getMessage("P-DVP-001_001_C"),
              screenFlag: true,
              cancelBtnFlg: false,
            };
            this.showConfirmDialog = true;
          };
        }
      }

      this.modifiedConfirmDialogOption = {
        message,
        okAction,
        screenFlag: true,
      };
      this.showConfirmDialog = true;
    },
    /**
     * 便削除処理（画面）
     */
    binDispDelete(vehicleIndex, binIndex) {
      const dispatchedFreightDtl = [
        ...this.dispatchVehicles[vehicleIndex].dispatchBinList[binIndex].dispatchedFreightDtl,
      ];

      const vehicle = {
        ...this.dispatchVehicles[vehicleIndex],
        dispatchBinList: this.dispatchVehicles[vehicleIndex].dispatchBinList.toSpliced(binIndex, 1),
      };

      this.dispatchVehicles.splice(vehicleIndex, 1, vehicle);
      this.undispatchedFreights = [...this.undispatchedFreights, ...dispatchedFreightDtl];
    },
    /**
     * 便削除処理（Post）
     */
    deletePost(vehicleIndex, binIndex) {
      const closeAction = () => {
        this.loadingCounter = 1;
        this.modifyVehicleDispatch().finally(() => {
          this.loadingCounter = 0;
        });
      };
      this.deleteDispatchVehicle(vehicleIndex, binIndex)
        .then(() => {
          // 画面上から便を削除
          this.binDispDelete(vehicleIndex, binIndex);

          // 正常終了時、完了ダイアログを表示する
          this.modifiedConfirmDialogOption = {
            title: this.$t("label.lbl_completed"),
            message: messsageUtil.getMessage("P-DVP-001_001_C"),
            screenFlag: true,
            cancelBtnFlg: false,
            okAction: closeAction,
            cancelAction: closeAction,
          };
          this.showConfirmDialog = true;
        })
        .catch((e) => {
          this.infoDialog = {
            isShow: true,
            title: appConfig.DIALOG.title,
            message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
          };
        });
    },
    register() {
      const dispatchVehicle = this.getRegiterDispatchVehicle();
      if (dispatchVehicle.some(({ driverUserSid }) => !driverUserSid)) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: messsageUtil.getMessage("P-DVP-001_002_E"),
        };
        return;
      }
      const dispatchData = dispatchVehicle.map(
        ({
          carSid,
          driverUserSid,
          transportSid,
          transportUpdateDatetime,
          fromDate,
          toDate,
          remarks,
          dispatchedFreightDtl,
        }) => {
          return {
            carSid,
            instDate: this.operationDateUTC,
            driverUserSid,
            transportSid: transportSid.startsWith(this.newTransportSidPrefix) ? null : transportSid,
            transportUpdateDatetime: transportUpdateDatetime,
            fromDate: fromDate ? fromDate : "",
            toDate: toDate ? toDate : "",
            remarks,
            dispatchedFreightDtl:
              dispatchedFreightDtl?.map(
                (
                  {
                    officeSidOrg,
                    pickupDeliveryDiv,
                    shipperClientCd,
                    packingSid,
                    pickupDeliveryPointSid,
                    pickupDeliveryPointCd,
                    packingUpdateDatetime,
                    deliveryDate,
                    deliveryTime,
                    pickupWayDiv,
                    packingSidOrg,
                  },
                  index
                ) => {
                  return {
                    autoNo2: (index + 1).toString(),
                    officeSidOrg,
                    pickupDeliveryDiv,
                    shipperClientCd,
                    moduleSid: null, // TODO 幹線対応後
                    packingSid,
                    pickupDeliveryPointSid,
                    pickupDeliveryPointCd,
                    packingUpdateDatetime,
                    deliveryDate,
                    deliveryTime,
                    pickupWayDiv,
                    packingSidOrg,
                  };
                }
              ) ?? [],
          };
        }
      );
      // 便が日を跨ぐ際の便開始終了日時計算
      for (let i = 0; i < dispatchData.length; i++) {
        if (dispatchData[i].fromDate != "" && dispatchData[i].toDate != "") {
          // 便開始日時を日付型で取得
          const fromDateInDt = new Date(dispatchData[i].fromDate);
          // 便終了日時を日付型で取得
          const toDateInDt = new Date(dispatchData[i].toDate);
          if (fromDateInDt > toDateInDt) {
            // 開始日時が終了日時よりも後であった場合
            // 終了日時を1日進める
            toDateInDt.setDate(toDateInDt.getDate() + 1);
            dispatchData[i].toDate = dateTimeHelper.toStringDate(
              "YYYY/MM/DD hh:mm:ss.SSS",
              toDateInDt
            );
          }
        }
      }
      let dataSendFlag = false;
      let schedulePastFlag = false;
      const opeDate = new Date(this.operationDateUTC);
      for (const vi of dispatchVehicle) {
        if (vi.planOnFlg === "1") {
          dataSendFlag = true;
        }
        for (const freight of vi.dispatchedFreightDtl ?? []) {
          if (freight.scheduleDate) {
            if (opeDate.getTime() > new Date(freight.scheduleDate).getTime()) {
              schedulePastFlag = true;
            }
          }
        }
        if (dataSendFlag && schedulePastFlag) break;
      }

      // 配車登録完了時のダイアログを表示する関数
      const openCompletedDialog = () => {
        const closeAction = () => {
          this.loadingCounter = 1;
          this.init().finally(() => {
            this.loadingCounter = 0;
          });
        };
        this.modifiedConfirmDialogOption = {
          title: this.$t("label.lbl_completed"),
          message: messsageUtil.getMessage("P-DVP-001_002_C"),
          screenFlag: true,
          cancelBtnFlg: false,
          okAction: closeAction,
          cancelAction: closeAction,
        };
        this.showConfirmDialog = true;
      };

      // 配車登録のエラーダイアログを表示する関数
      const openErrorDialog = (e) => {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      };

      // 配車登録を実行する関数
      const registerAction = () => {
        this.loadingCounter = 1;
        this.registerDispatchVehicle(dispatchData)
          .then(() => {
            this.loadingCounter = 0;
            // 正常終了時、完了ダイアログを表示する
            openCompletedDialog();
          })
          .catch((e) => {
            this.loadingCounter = 0;
            openErrorDialog(e);
          });
      };

      // データ送信フラグが「確定（1）」の貨物がある場合、確認ダイアログを表示する関数
      const openDataSendedConfirmDialog = () => {
        this.modifiedConfirmDialogOption = {
          message: messsageUtil.getMessage("P-DVP-001_001_W"),
          screenFlag: true,
          okAction: registerAction,
        };
        this.showConfirmDialog = true;
      };

      if (schedulePastFlag) {
        // 予定日(期限)が運行日よりも過去日の場合、確認ダイアログを表示
        this.modifiedConfirmDialogOption = {
          message: messsageUtil.getMessage("P-DVP-001_004_W"),
          title: appConfig.DIALOG.confirm,
          screenFlag: true,
          cancelBtnFlg: true,
          okAction: dataSendFlag ? openDataSendedConfirmDialog : registerAction,
        };
        this.showConfirmDialog = true;
      } else if (dataSendFlag) {
        openDataSendedConfirmDialog();
      } else {
        registerAction();
      }
    },
    fetchUndispatchedFreightList(undispatchedFreightFilters, undispatchedFreightSorts) {
      const config = this.$httpClient.createGetApiRequestConfig();

      let filter = {};
      for (const [name, value] of Object.entries(undispatchedFreightFilters)) {
        if (!!value || !!value.length) {
          if (name.startsWith("scheduleDate") && value.match(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/g)) {
            filter[name] = dateTimeHelper.convertUTC(value + " 00:00:00");
          } else {
            filter[name] = value;
          }
        }
      }

      config.params.filter = { ...filter };
      config.params.sorts = [...undispatchedFreightSorts];

      // 接続先のAPI_URLを入力
      return this.$httpClient
        .secureGet(appConfig.API_URL.BIZ_UNDISPATCHED_FREIGHT_SEARCH, config)
        .then(
          (response) => {
            const data = response.data;
            if (data.resCom.resComCode !== "000") {
              const message =
                data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }

            return [
              data.resIdv.undispatchFreightTotalCount,
              data.resIdv.undispatchFreightFilter,
              data.resIdv.undispatchFreight,
            ];
          },
          (error) => {
            const message =
              error?.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },
    async fetchDispatchVehicleSearch(operationDate, vehicleSort, visibleBinList) {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.operationDate = operationDate;
      config.params.vehicleSort1 = vehicleSort.vehicleSort1;
      config.params.vehicleSortOrder1 = vehicleSort.vehicleSortOrder1;
      config.params.vehicleSort2 = vehicleSort.vehicleSort2;
      config.params.vehicleSortOrder2 = vehicleSort.vehicleSortOrder2;
      config.params.visibleBinList = visibleBinList;
      // 営業所コードを設定
      config.params.officeSid = config.params.reqComOfficeSid;
      config.params.reqComPageLimit = this.pagingMax;

      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_DISPATCH_LIST, config).then(
        (response) => {
          const data = response.data;
          if (data.resCom.resComCode !== "000") {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }

          return data.resIdv.dispatchVehicle;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    fetchDrivers() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_DRIVER_SEARCH, config).then(
        (response) => {
          const data = response.data;
          if (data.resCom.resComCode !== "000") {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
          return data.resIdv.drivers;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    registerDispatchVehicle(dispatchDataList) {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_002; // 画面ID
      body.reqIdv.dispatchPlanList = dispatchDataList;

      // 接続先のAPI_URLを入力
      return this.$httpClient
        .securePost(appConfig.API_URL.BIZ_DISPATCH_PLAN, body, appConfig.APP_CONFIG)
        .then(
          (response) => {
            // エラー時
            const jsonData = response.data;
            if (jsonData.resCom.resComCode !== "000") {
              const message =
                jsonData.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
            return;
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },
    getRegiterDispatchVehicle() {
      const registerDispatchVehicle = [];
      const dispatches = this.dispatchVehicles.flatMap(
        ({ carSid, dispatchBinList }) =>
          dispatchBinList?.map((dispatch) => ({ carSid, ...dispatch })) ?? []
      );
      const originals = this.originalDispatchVehicles.flatMap(
        ({ carSid, dispatchBinList }) =>
          dispatchBinList?.map((dispatch) => ({ carSid, ...dispatch })) ?? []
      );
      for (const original of originals) {
        const dispatch = dispatches.find(
          ({ transportSid }) => transportSid === original.transportSid
        );
        if (!dispatch) {
          continue;
        }

        // ドライバーが異なる場合
        if (original.driverUserSid !== dispatch.driverUserSid) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の開始時刻が異なる場合
        if (original.fromDate !== dispatch.fromDate) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の終了時刻が異なる場合
        if (original.toDate !== dispatch.toDate) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の備考が異なる場合
        if ((original.remarks?.toString() ?? "") !== (dispatch.remarks?.toString() ?? "")) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        const originalFreights = original.dispatchedFreightDtl;
        const dispatchFreights = dispatch.dispatchedFreightDtl;

        // 配車済み貨物の数が異なる場合
        if (originalFreights.length !== dispatchFreights.length) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        for (let i = 0; i < originalFreights.length; i++) {
          const of = originalFreights[i];
          const df = dispatchFreights[i];

          // 配車済み貨物の順番が異なる場合
          if (of.packingSid !== df.packingSid || of.pickupDeliveryDiv != df.pickupDeliveryDiv) {
            registerDispatchVehicle.push(dispatch);
            break;
          }
          // 配車済み貨物の納品日時が異なる場合
          if (of.deliveryDate !== df.deliveryDate || of.deliveryTime !== df.deliveryTime) {
            registerDispatchVehicle.push(dispatch);
            break;
          }
        }
      }

      // 新規登録の場合
      dispatches
        .filter(({ transportSid }) => transportSid.startsWith(this.newTransportSidPrefix))
        .forEach((dispatch) => registerDispatchVehicle.push(dispatch));

      return registerDispatchVehicle;
    },

    deleteDispatchVehicle(vehicleIndex, binIndex) {
      const deleteTargetBin = this.originalDispatchVehicles[vehicleIndex].dispatchBinList[binIndex];

      const deleteDispatchPlan = {
        transportSid: deleteTargetBin.transportSid,
        transportUpdateDatetime: deleteTargetBin.transportUpdateDatetime,
        dispatchedFreightDtl:
          deleteTargetBin.dispatchedFreightDtl?.map(
            ({ pickupDeliveryDiv, packingSid, packingUpdateDatetime }) => {
              return {
                pickupDeliveryDiv,
                moduleSid: null, // TODO 幹線対応後
                packingSid,
                packingUpdateDatetime,
              };
            }
          ) ?? {},
      };

      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_002; // 画面ID
      body.reqIdv.deleteDispatchPlan = deleteDispatchPlan;

      // 接続先のAPI_URLを入力
      return this.$httpClient
        .securePost(appConfig.API_URL.BIZ_DISPATCH_PLAN_DELETE, body, appConfig.APP_CONFIG)
        .then(
          (response) => {
            const jsonData = response.data;
            // エラー時
            if (jsonData.resCom.resComCode !== "000") {
              const message =
                jsonData.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },
    getMyOfficeAddress() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // 拠点SID
      config.params.baseSid = config.params.reqComOfficeSid;
      // 拠点管理種別（2=営業所を取得）
      config.params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      config.params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      config.params.selfEstablishedLocationsKbn = "1";

      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.MST_BIZ_BASE_SEARCH, config).then(
        (response) => {
          const data = JSON.parse(JSON.stringify(response.data));
          if (data.resCom.resComCode !== "000" || !data.resIdv.baseInfoList) {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
          return data.resIdv.baseInfoList[0].address1 + data.resIdv.baseInfoList[0].address2;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    async filterUndispatchFreights() {
      try {
        const [totalCount, filters, undispatchedFreights] = await this.fetchUndispatchedFreightList(
          this.filterItems,
          this.sortItems
        );

        // 絞り込み直前の配車済みの貨物
        const dispatchFreightIds = new Set(
          this.dispatchVehicles
            ?.flatMap(({ dispatchBinList }) => dispatchBinList ?? [])
            ?.flatMap(({ dispatchedFreightDtl }) => dispatchedFreightDtl ?? [])
            .map(({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`)
        );

        // 前回表示時点での（未配車貨物がまだ配車されていない状態の）配車済みの貨物
        const originalDispatchFreightIds = new Set(
          this.originalDispatchVehicles
            ?.flatMap(({ dispatchBinList }) => dispatchBinList ?? [])
            ?.flatMap(({ dispatchedFreightDtl }) => dispatchedFreightDtl ?? [])
            .map(({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`)
        );

        // 配車済み貨物から未配車に移動された貨物
        const freights =
          this.undispatchedFreights?.filter(({ pickupDeliveryDiv, packingSid }) =>
            originalDispatchFreightIds.has(`${pickupDeliveryDiv}_${packingSid}`)
          ) ?? [];

        // 絞り込み直前に配車済みの未配車貨物を新たに取得した未配車貨物の一覧から除き、
        // 配車済みから未配車に移動された貨物を加える
        this.undispatchedFreights = [
          ...undispatchedFreights.filter(
            (freight) =>
              !dispatchFreightIds.has(`${freight.pickupDeliveryDiv}_${freight.packingSid}`)
          ),
          ...freights,
        ];

        // 未配車貨物が配車されていない状態の配車済みの貨物と絞り込み直前の配車済み貨物を比較して
        // 絞り込み直前の貨物のみに含まれるもの(未配車貨物から便に配車した貨物)を取得
        const unregisteredDispatchFreightIds = dispatchFreightIds.difference(
          originalDispatchFreightIds
        );

        // 総件数 = 再取得した総件数 + 配車済みから未配車に移動された貨物数 - 未配車貨物から便に配車した貨物数
        this.undispatchFreightTotalCount =
          totalCount + (freights?.length ?? 0) - unregisteredDispatchFreightIds.size;
        this.filters = this.mergeFilters(this.filters, filters);
        this.originalUndispatchFreightCount = this.undispatchedFreights?.length ?? 0;
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },
    /**
     * 現在の絞り込み条件と最新の絞り込み条件を組み合わせる
     *
     * @param {Array} currentFilters 現在の絞り込み条件
     * @param {Array} latestFilters 最新の絞り込み条件
     *
     * @returns 現在の絞り込み条件に最新の絞り込み条件を組み合わせた新しい絞り込み条件
     */
    mergeFilters(currentFilters, latestFilters) {
      currentFilters ??= [];
      latestFilters ??= [];
      if (!currentFilters.length) {
        return latestFilters;
      }
      if (!latestFilters.length) {
        return currentFilters;
      }
      const equalFilter = (f1, f2, equalProps = ["name", "type"]) => {
        return equalProps.every((prop) => f1[prop] === f2[prop]);
      };

      // 現在の絞り込み条件と最新の絞り込み条件の values を組み合わせる関数
      const mergeValues = (baseValues, otherValues) => {
        baseValues ??= [];
        otherValues ??= [];
        if (!otherValues.length) {
          return baseValues;
        }
        if (!baseValues.length) {
          return otherValues;
        }
        const valuesMap = [...baseValues, ...otherValues].reduce(
          (map, { value, text }) => map.set(value, text),
          new Map()
        );
        return Array.from(valuesMap.keys())
          .toSorted()
          .map((value) => ({ value, text: valuesMap.get(value) }));
      };

      // 現在の絞り込み条件と最新の絞り込み条件の groupValues を組み合わせる関数
      const mergeGroupValues = (baseGroupValues, otherGroupValues) => {
        baseGroupValues ??= [];
        otherGroupValues ??= [];
        if (!otherGroupValues.length) {
          return baseGroupValues;
        }
        if (!baseGroupValues.length) {
          return otherGroupValues;
        }

        const groupValuesMap = [...baseGroupValues, ...otherGroupValues].reduce(
          (map, { label, values }) => {
            if (map.has(label)) {
              return map.set(label, mergeValues(map.get(label), values));
            } else {
              return map.set(label, values);
            }
          },
          new Map()
        );
        return Array.from(groupValuesMap.keys())
          .toSorted()
          .map((label) => ({ label, values: groupValuesMap.get(label) }));
      };

      const currentFilterMap = currentFilters.reduce((map, f) => map.set(f.name, f), new Map());
      const latestFilterMap = latestFilters.reduce((map, f) => map.set(f.name, f), new Map());

      // valuesに値がある絞り込みの種類
      const valuesTypeSet = new Set(["radio", "select", "autocomplete"]);

      // 現在の絞り込みと最新の絞り込み条件を組み合わせる
      const filters = [];
      for (const [name, currentFilter] of currentFilterMap.entries()) {
        const latestFilter = latestFilterMap.get(name);
        if (equalFilter(currentFilter, latestFilter)) {
          // 最新の絞り込み条件を取得した際に、表示される絞り込み条件を維持するために、
          // 初期値(initalValueとinitialValues)は最新の絞り込み条件にあるものを利用する
          const filter = {
            ...currentFilter,
            initialValue: latestFilter.initialValue,
            initialValues: latestFilter.initialValues,
          };
          if (currentFilter.group) {
            filter.groupValues = mergeGroupValues(
              currentFilter.groupValues,
              latestFilter.groupValues
            );
          } else if (valuesTypeSet.has(currentFilter.type)) {
            filter.values = mergeValues(currentFilter.values, latestFilter.values);
          }
          filters.push(filter);
        } else {
          filters.push(currentFilter);
        }
      }

      return filters;
    },

    /**
     * 未保存の編集中の配車情報（便、未配車貨物）と新しく取得した配車情報を組み合わせる
     *
     * 以下のように組み合わせた配車車両情報が返却される
     * 1. 新しく追加された便、配車情報が更新された便
     * 2. 1以外の便で、1で配車済みの貨物を除いた編集中の便
     * 3. 1,2以外の新しく取得した便から、1, 2で配車済みの貨物を除いた便
     *
     * @param {Array} newDispatchVehicle 新しく取得した配車車両
     * @param {Array} newUndispatchFreight 新しく取得した未配車貨物
     * @param {Array} editingDispatchVehicle 編集中の配車車両
     * @param {Array} editingUndispatchFreight 編集中の未配車貨物
     * @param {Array} oldOriginalDispatchVehicle 前回取得時の配車車両
     *
     * @returns [配車車両情報, 未配車貨物]
     */
    mergeDispatchFreights(
      newDispatchVehicle,
      newUndispatchFreight,
      editingDispatchVehicle,
      editingUndispatchFreight,
      oldOriginalDispatchVehicle
    ) {
      const oldOriginalBinList = oldOriginalDispatchVehicle.flatMap(
        ({ dispatchBinList }) => dispatchBinList ?? []
      );

      const oldOriginalTransportSids = oldOriginalBinList.map(({ transportSid }) => transportSid);

      // 前回の画面表示時より後に新しく追加された便
      const newBins = new Map();
      // 前回の画面表示時より後に更新された便
      const updatedBins = new Map();
      // 前回の画面表示時から変わらない便
      const unupdatedBins = new Map();
      for (const { carSid, dispatchBinList } of newDispatchVehicle) {
        const newBinList = [];
        const updatedBinList = [];
        const unupdatedBinList = [];
        for (const newBin of dispatchBinList ?? []) {
          // 新規で追加された便かどうか
          const index = oldOriginalTransportSids.indexOf(newBin.transportSid);
          if (index === -1) {
            newBinList.push(newBin);
            continue;
          }

          const oldDispatchedFreights = oldOriginalBinList[index].dispatchedFreightDtl ?? [];
          const newDispatchedFreights = newBin.dispatchedFreightDtl ?? [];

          // 配車されている貨物の数に違いがあるか
          if (oldDispatchedFreights.length !== newDispatchedFreights.length) {
            updatedBinList.push(newBin);
            continue;
          }

          let unupdated = true;
          // 貨物の順番が異なるか
          for (let i = 0; i < newDispatchedFreights.length; i++) {
            const oldFreightId = `${oldDispatchedFreights[i].pickupDeliveryDiv}_${oldDispatchedFreights[i].packingSid}`;
            const newFreightId = `${newDispatchedFreights[i].pickupDeliveryDiv}_${newDispatchedFreights[i].packingSid}`;
            if (oldFreightId !== newFreightId) {
              updatedBinList.push(newBin);
              unupdated = false;
              break;
            }
          }

          // 更新されていない場合
          if (unupdated) {
            unupdatedBinList.push(newBin);
          }
        }
        if (newBinList.length !== 0) newBins.set(carSid, newBinList);
        if (updatedBinList.length !== 0) updatedBins.set(carSid, updatedBinList);
        if (unupdatedBinList.length !== 0) unupdatedBins.set(carSid, unupdatedBinList);
      }

      // 前回画面表示より後に削除された便
      const deletedBins = new Map();
      for (const { carSid, dispatchBinList } of oldOriginalDispatchVehicle) {
        const newBinList = newBins.get(carSid) ?? [];
        const updatedBinList = updatedBins.get(carSid) ?? [];
        const unupdatedBinList = unupdatedBins.get(carSid) ?? [];
        const binList = [...newBinList, ...updatedBinList, ...unupdatedBinList];
        const deletedBinList = (dispatchBinList ?? []).filter(({ transportSid }) =>
          binList.every((bin) => bin.transportSid !== transportSid)
        );

        if (deletedBinList.length !== 0) deletedBins.set(carSid, deletedBinList);
      }

      // 現在配車可能な全貨物（新しく取得した未配車貨物と配車済み貨物）の荷姿Sid
      const allFreightIds = new Set(
        newUndispatchFreight.map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );
      for (const { dispatchBinList } of newDispatchVehicle) {
        for (const { dispatchedFreightDtl } of dispatchBinList ?? []) {
          for (const { pickupDeliveryDiv, packingSid } of dispatchedFreightDtl ?? []) {
            allFreightIds.add(`${pickupDeliveryDiv}_${packingSid}`);
          }
        }
      }

      // 新しく追加された便に配車されている貨物の荷姿SID
      const newFreightIds = new Set(
        Array.from(newBins.values())
          .flat()
          .flatMap((bin) => bin.dispatchedFreightDtl ?? [])
          .map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );

      // 更新された便に配車されている貨物の荷姿SID
      const updatedDispatchedFreightIds = new Set(
        Array.from(updatedBins.values())
          .flat()
          .flatMap((bin) => bin.dispatchedFreightDtl ?? [])
          .map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );

      // 更新されていない便情報のうち、更新前も未編集だった便情報を取得
      const uneditedBins = new Map();
      for (const [carSid, unupdatedBinList] of unupdatedBins.entries()) {
        const editingVehicle = editingDispatchVehicle.find((vehicle) => vehicle.carSid === carSid);
        const binList = [];
        for (const bin of unupdatedBinList) {
          const editingBin = editingVehicle.dispatchBinList.find(
            ({ transportSid }) => transportSid === bin.transportSid
          );
          const editingFreights = editingBin?.dispatchedFreightDtl ?? [];
          const editingPakingSidList = editingFreights.map(
            (f) => `${f.pickupDeliveryDiv}_${f.packingSid}`
          );
          if (
            bin.dispatchedFreightDtl.length === editingFreights.length &&
            bin.dispatchedFreightDtl.every(({ pickupDeliveryDiv, packingSid }) =>
              editingPakingSidList.includes(`${pickupDeliveryDiv}_${packingSid}`)
            )
          ) {
            binList.push(bin);
          }
        }

        if (binList.length !== 0) uneditedBins.set(carSid, binList);
      }

      // 編集中の貨物のうち、更新済みの便に移動している貨物を取得
      const editUpdatedPackingSids = new Set();
      for (const [carSid, updatedBinList] of updatedBins.entries()) {
        const updatedTransportSids = new Set(updatedBinList.map((bin) => bin.transportSid));
        const editingVehicle = editingDispatchVehicle.find((vehicle) => vehicle.carSid === carSid);
        const editingBinMap = editingVehicle.dispatchBinList
          .filter(({ transportSid }) => updatedTransportSids.has(transportSid))
          .reduce((map, bin) => map.set(bin.transportSid, bin), new Map());

        for (const { transportSid, dispatchedFreightDtl } of updatedBinList) {
          const editingBin = editingBinMap.get(transportSid);
          for (const { pickupDeliveryDiv, packingSid } of editingBin?.dispatchedFreightDtl ?? []) {
            const editFreightId = `${pickupDeliveryDiv}_${packingSid}`;
            if (
              dispatchedFreightDtl.every(
                (freight) => `${freight.pickupDeliveryDiv}_${freight.packingSid}` !== editFreightId
              )
            ) {
              editUpdatedPackingSids.add(editFreightId);
            }
          }
        }
      }

      // 前回表示時の編集中の便で、画面更新後の配車済みの貨物を除いた、未更新な便を抽出
      const editingBins = new Map();
      const editingFreightIds = new Set();
      for (const { carSid, dispatchBinList } of editingDispatchVehicle) {
        const unupdatedBinList = unupdatedBins.get(carSid) ?? [];

        const editingBinList = [];
        for (const bin of dispatchBinList ?? []) {
          if (
            !bin.transportSid.startsWith(this.newTransportSidPrefix) &&
            unupdatedBinList.every(({ transportSid }) => transportSid !== bin.transportSid)
          ) {
            break;
          }

          const unupdatedBin = unupdatedBinList.find(
            ({ transportSid }) => transportSid === bin.transportSid
          );

          const editUpdatedFreights =
            unupdatedBin?.dispatchedFreightDtl.filter(({ pickupDeliveryDiv, packingSid }) =>
              editUpdatedPackingSids.has(`${pickupDeliveryDiv}_${packingSid}`)
            ) ?? [];

          // 更新または新規追加された便に配車済みの貨物を除く
          const dispatchedFreights = [...editUpdatedFreights];

          const uneditDispatchFreightBins = unupdatedBins.get(carSid) ?? [];
          const uneditDispatchFreightList = uneditDispatchFreightBins.flatMap(
            (bin) => bin.dispatchedFreightDtl ?? []
          );
          for (const freight of bin.dispatchedFreightDtl) {
            const freightId = `${freight.pickupDeliveryDiv}_${freight.packingSid}`;
            if (
              !newFreightIds.has(freightId) &&
              !updatedDispatchedFreightIds.has(freightId) &&
              allFreightIds.has(freightId)
            ) {
              // 更新日時を反映するため未配車貨物リスト内に荷姿Sidと集荷配達区分が一致するデータがある場合
              // freightではなく、未配車貨物リストから値を返す
              const editUndispatchFreight = newUndispatchFreight.find(
                (el) => freightId === `${el.pickupDeliveryDiv}_${el.packingSid}`
              );
              // 貨物情報を最新の情報に変更
              const uneditDispatchFreight = uneditDispatchFreightList.find(
                (el) => freightId === `${el.pickupDeliveryDiv}_${el.packingSid}`
              );
              const latestFreight = uneditDispatchFreight ? uneditDispatchFreight : freight;
              dispatchedFreights.push(
                editUndispatchFreight ? editUndispatchFreight : latestFreight
              );
              editingFreightIds.add(freightId);
            }
          }

          editingBinList.push({
            ...bin,
            dispatchedFreightDtl: dispatchedFreights,
          });
        }

        if (editingBinList.length !== 0) editingBins.set(carSid, editingBinList);
      }

      // 編集中の配車と新しく取得した配車の組み合わせ
      const dispatchedVehicle = [];
      for (const newVehicle of newDispatchVehicle) {
        const updatedBinList = updatedBins.get(newVehicle.carSid) ?? [];
        const newBinLst = newBins.get(newVehicle.carSid) ?? [];
        const editingBinList = editingBins.get(newVehicle.carSid) ?? [];
        const vehicle = {
          ...newVehicle,
          dispatchBinList: [...updatedBinList, ...newBinLst, ...editingBinList],
        };
        dispatchedVehicle.push(vehicle);
      }

      // 配車済みの貨物を除いた未配車貨物のリスト
      const undispatchVehicle = newUndispatchFreight.filter(
        ({ pickupDeliveryDiv, packingSid }) =>
          !editingFreightIds.has(`${pickupDeliveryDiv}_${packingSid}`)
      );

      // 画面更新前の編集中の未配車貨物から配車可能な貨物を追加する
      const undispatchPakcingSids = new Set(
        undispatchVehicle.map(
          ({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`
        )
      );
      for (const freight of editingUndispatchFreight) {
        const freightId = `${freight.pickupDeliveryDiv}_${freight.packingSid}`;
        if (allFreightIds.has(freightId) && !undispatchPakcingSids.has(freightId)) {
          undispatchVehicle.push(freight);
        }
      }

      return [dispatchedVehicle, undispatchVehicle];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.selected-freight {
  filter: brightness(85%);
  // position: relative;
  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   display: inline-block;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   background-color: rgb(140 140 140 / 20%);
  // }
}
.freight-ghost {
  opacity: 0.5;
}
// 未配車エリアの納品日非表示
.undispatch ::v-deep {
  .freightDateTimePicker {
    display: none;
  }
}
</style>
